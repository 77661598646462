.contentToggle {
  background-color: transparent;
  border: none;
  color: rgba(0,0,0,0.6);
  cursor: pointer;
  font-family: inherit;
  font-weight: 700;
  margin-bottom: var(--spacing-xs-3);
  padding: 0;
  text-align: left;
}

:global(.theme-dark) .contentToggle,
:global(.theme-dark).contentToggle {
  color: var(--color-gray-700);
}

.content {
  display: flex;
  flex-direction: column;
}

.applyBtn {
  width: 176px;
  height: var(--spacing-md);
  margin-top: var(--spacing-sm-2);
}
