.whoop-your-way-card {
  button {
    // Force the gradient on the button
    background: linear-gradient(90deg, #5E22DC 0.53%, #289CB6 97.4%);
    border: none;
    color: white;
    min-width: 160px;
  }
}


.image-container {
  overflow: hidden;
  display: flex;

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  img {
    animation: slide 20s linear infinite;
    max-width: calc(328px * 4);
  }


}
