.inputs {
  display: block;
  width: 100%;
  margin: 2rem auto;
  .inputs:invalid {
    border-color: var(--whoop-color-red);
  }
  Input {
    color: #4d4d4d !important;
    font-weight: 400;
    letter-spacing: -0.48px !important;
    height: 60px !important;
  }
}
