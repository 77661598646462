.badge {
  padding: 4px;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  width: fit-content;
  border-radius: 2px;
  margin: 4px;

  background: var(--whoop-color-black);
  color: var(--whoop-color-white);

  &.white {
    background: var(--whoop-color-white);
    color: var(--whoop-color-black);
  }

  &.pro {
    background: var(--whoop-color-pro-gold);
    color: var(--whoop-color-black);
  }

  &.gray {
    background: var(--whoop-color-gray-700);
    color: var(--whoop-color-white);
  }

  &.transparent {
    background: var(--whoop-color-white-alpha-700);
    color: var(--whoop-color-black);
  }

  &.blue {
    background-color: var(--whoop-color-accent-blue);
    color: var(--whoop-color-white);
  }
}
