:global(.theme-dark) .dropdown,
:global(.theme-dark).dropdown {
  .dropdownButton {
    background: var(--color-white-alpha-100);
    border-color: var(--color-white-alpha-100);
    color: var(--color-white);
  }

  .dropdownMenu {
    background: var(--color-background-darkblue);

    .menuItem {
      background-color: var(--color-background-blue);
      color: var(--color-white);

      &:hover {
        background: var(--color-white-alpha-200);
      }
    }
  }

  .checkIcon {
    svg > path{
      fill: var(--color-white);
    }
  }
}

:global(.theme-currency-dark) .dropdown,
:global(.theme-currency-dark).dropdown {
  p {
    font-size: 15px;
  }

  .dropdownButton {
    background: var(--color-black);
    border: none;
    color: var(--color-white);

    &:not([disabled]):before {
      background: var(--color-white-alpha-600);
    }
  }

  .dropdownMenu {
    width: 130px;
    background: var(--color-background-darkblue);

    .menuItem {
      background-color: var(--color-background-blue);
      color: var(--color-white);

      &:hover {
        background: var(--color-white-alpha-200);
      }
    }
  }

  .checkIcon {
    svg > path{
      fill: var(--color-white);
    }
  }
}

:global(.theme-currency) .dropdown,
:global(.theme-currency).dropdown {
  p {
    font-size: 15px;
  }

  .dropdownButton {
    border: none;
  }

  .dropdownMenu {
    width: 130px;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.dropdownButton {
  height: 100%;
  width: 100%;
  border: 1px solid var(--color-gray-600);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0px;

  .icon {
    width: 12px;
    height: 12px;
    margin-right: 16px;
    margin-left: auto;
  }

  .text {
    margin-left: 16px;
  }
}

.dropdownMenu {
  box-shadow: 0px 0px 8px var(--color-black-alpha-300);
  border-radius: 8px;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 300px;
  z-index: 1;
  margin-top: 4px;
  background: var(--color-white);

  .menuItem {
    align-items: center;
    padding: 16px 12px 16px 16px;
    gap: 8px;
    background-color: var(--color-white);
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover {
      background: var(--color-black-alpha-50);
    }

    .checkIcon {
      width: 16px;
      height: 16px;
      margin-left: auto;
    }
  }
}

.round {
  border-radius: 8px;
}

.squared {
  border-radius: 3px;
}
