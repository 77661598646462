h3.mini-description {
  font-family: var(--whoop-font-family-semibold);
  margin-bottom: 12px;
}

.selected-option {
  font-family: var(--whoop-font-family-semibold);
  padding-left: 5px;
  display: inline-block;
  white-space: nowrap;
  padding-top: 13px;
  padding-bottom: 13px;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.miniProductBadge {
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  width: fit-content;
  border-radius: 2px;
  position: relative;
  margin: var(--spacing-xs-2);
  background: var(--whoop-color-black);
  color: var(--whoop-color-white);
  vertical-align: inherit;
  display: inline-block;
  margin-left: 5px;
  margin-top: 0px;

  &.white {
    background: var(--whoop-color-white);
    color: var(--whoop-color-black);
  }

  &.pro {
    background: var(--whoop-color-pro-gold);
    color: var(--whoop-color-black);
  }

  &.gray {
    background: var(--whoop-color-gray-700);
    color: var(--whoop-color-white);
  }
}

.product-card {
  margin-bottom: 26px;

  .item-info {
    display: table-row;
    table-layout: fixed;
    margin: 28px 0;
    width: 100%;

    .thumb {
      display: table-cell;
      width: 104px;

      img {
        max-width: 104px;
        max-height: 104px;
        margin: auto;
        display: block;
      }
    }

    .info {
      display: table-cell;
      vertical-align: top;
      padding-left: 24px;
      max-width: 300px;
      width: 300px;

      & > :first-child {
        margin: 0;
      }

      .variants {
        margin: 0;
      }
    }

    .product-link {
      margin-top: 12px;
      padding-left: 4px;
    }

    .product-link-text {
      font-family: var(--whoop-font-family-semibold);
      color: black;
      text-decoration: underline;
      cursor: pointer;
    }

    a {
      font-size: 14px;
      font-weight: 600;
      text-decoration-color: black;
    }
  }

  .vert-bar {
    display: inline-block;
    height: 24px;
    width: 1px;
    vertical-align: middle;
    margin: 0 var(--spacing-xs);
    background: var(--color-black);
    margin-top: 4px;
  }

}

.selectors {
  display: flex;
}
