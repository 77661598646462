$spacing: (
  'xs': 4px,
  'xs-2': 8px,
  'xs-3': 12px,
  'xs-4': 16px,
  'xs-5': 20px,
  'sm': 24px,
  'sm-2': 28px,
  'sm-3': 32px,
  'sm-4': 36px,
  'sm-5': 40px,
  'md': 48px,
  'md-2': 56px,
  'md-3': 64px,
  'md-4': 72px,
  'md-5': 80px,
  'lg': 88px,
  'lg-2': 96px,
  'lg-3': 104px,
  'lg-4': 112px,
  'lg-5': 120px,
  'auto': auto
);

:export {
  @each $name, $size in $spacing {
    #{$name}: $size;
  }
}

:global(html) {
  @each $name, $size in $spacing {
    #{'--spacing-' + unquote($name)}: $size;
  }
}

:global {
  .whoop-ui,
  .whoop-spacing {
    @each $name, $size in $spacing {
      .m-#{unquote($name)} {
        margin: $size;
      }
      .mx-#{unquote($name)} {
        margin: 0 $size;
      }
      .my-#{unquote($name)} {
        margin: $size 0;
      }
      .mt-#{unquote($name)} {
        margin-top: $size;
      }
      .mb-#{unquote($name)} {
        margin-bottom: $size;
      }
      .ml-#{unquote($name)} {
        margin-left: $size;
      }
      .mr-#{unquote($name)} {
        margin-right: $size;
      }
      .p-#{unquote($name)} {
        padding: $size;
      }
      .px-#{unquote($name)} {
        padding: 0 $size;
      }
      .py-#{unquote($name)} {
        padding: $size 0;
      }
      .pt-#{unquote($name)} {
        padding-top: $size;
      }
      .pb-#{unquote($name)} {
        padding-bottom: $size;
      }
      .pl-#{unquote($name)} {
        padding-left: $size;
      }
      .pr-#{unquote($name)} {
        padding-right: $size;
      }
    }
  }
}
