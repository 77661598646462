.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 369px;
  padding: 104px 117px;

  .title {
    font-size: 35px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: -0.04em;
    margin: 0;
    text-transform: uppercase;
  }

  .description {
    max-width: 335px;
    text-align: center;
    margin: 20px 0;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.03em;
  }

  .upgradeButton {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
  }
}
