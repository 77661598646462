@import '../../variables';

.thumbnail-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  --max-image-count: 5;
  --thumbnail-size: 84px; // this must be exactly the width of the thumbnail

  @media screen and (max-width: $screen-s-max) {
    --max-image-count: 4;
    --thumbnail-size: 52px; // this must be exactly the width of the thumbnail

    .thumbnails > a {

      & > * {
        width: 32px;
        height: 32px;
      }
    }
  }

  & > button {
    height: var(--thumbnail-size);
    border-radius: 2px;
    transition: color 400ms ease;

    &:before {
      // this is necessary since we are stretching the button vertically
      // to fit the thumbnails
      padding: 50% !important;
    }

    &:hover {
      color: var(--color-black);
      --color-hover-alpha: none;
    }

    &[disabled] {
      visibility: hidden;
    }
  }

  .thumbnails {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(
      var(--thumbnail-size) * min(var(--max-image-count), var(--image-count))
    );
    overflow: hidden;

    & > a {
      position: relative;
      display: block;
      border: 2px solid transparent;
      border-radius: 2px;
      cursor: pointer;
      margin: var(--spacing-xs-2);
      transition: border 200ms ease-in-out, transform 400ms ease-in-out;
      user-select: none;
      @media screen and (max-width: $screen-s-max) {
        margin: var(--spacing-xs);
      }

      // SASS has trouble if we have a complex nested calculation
      // for that reason this calculation is split into multiple variables
      --image-count-minus-max: calc(var(--image-count) - var(--max-image-count));
      --image-index-minus-mid: calc(var(--image-index) - 2);
      --calc-min: min(var(--image-count-minus-max), var(--image-index-minus-mid));
      --calc-max: max(0, var(--calc-min));
      transform: translateX(
          calc(-1 * var(--thumbnail-size) * var(--calc-max))
      );

      & > * {
        width: 64px;
        height: 64px;
        @media screen and (max-width: $screen-s-max) {
          width: 40px;
          height: 40px;
        }
      }

      &.is-selected {
        border-color: black;
      }

      img {
        object-fit: contain;
      }
    }
  }
}
