.link-button {
  // remove button styles
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  width: unset;

  font-family: var(--whoop-font-family-semibold);
  font-size: 14px;
  letter-spacing: .02em;
  cursor: pointer;
  white-space: nowrap;
  transition: color 200ms ease;

  &:hover {
    color: var(--color-black-alpha-700);
  }

  svg {
    // icon
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-left: 4px;
    box-sizing: border-box;
  }
}
