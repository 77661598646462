@import '../../variables';

.slideshow {
  position: relative;

  & > div:first-child {
    height: 100%;
  }

  &.parent-sizing {
    :global {
      .flickity-viewport {
        height: 100% !important; // Flickity tries to hijack this, but we want this height
      }
    }
  }

  :global {
    .flickity-viewport {
      max-height: 100%;
    }

    .flickity-page-dots {
      opacity: 0;
    }

    .flickity-prev-next-button {
      background: var(--color-white);
      border-radius: 0;
      color: var(--color-gray-700);
      transition: 400ms;


      &:hover {
        color: var(--color-black);
      }

      &[disabled] {
        opacity: 0 !important;
      }

      @media screen and (min-width: $screen-s-max) {
        opacity: 0;
      }
    }

  //  .flickity-prev-next-button {
  //    top: 0;
  //    height: 100%;
  //    border-radius: 0;
  //    transform: none;
  //    opacity: 0;
  //    color: var(--color-black-alpha-500);
  //    background: var(--color-white-alpha-300);
  //
  //    transition: background 200ms ease, color 200ms ease, opacity 400ms ease;
  //
  //    &[disabled] {
  //      opacity: 0 !important;
  //    }
  //
  //    &:hover {
  //      background: var(--color-white-alpha-500);
  //      color: var(--color-black);
  //    }
  //
  //    &.previous {
  //      left: 0;
  //    }
  //
  //    &.next {
  //      right: 0;
  //      --gradient-angle: 270deg;
  //    }
  //  }
  }

  @media screen and (min-width: $screen-s-max) {
    &:hover {
      & > :global(div.flickity-enabled) {
        & > :global(button.flickity-prev-next-button) {
          opacity: 1;
        }
      }
    }
  }


  &.with-dots {
    margin-bottom: var(--spacing-sm-4);
    :global {
      .flickity-page-dots {
        opacity: 1;
      }
    }
  }

  & > button {
    position: absolute;
    height: 100%;
    top: 0;
    background: #ffffff50;
    opacity: 0;
    transition: opacity 400ms ease-in-out;

    @media screen and (max-width: $screen-s-max) {
      display: none;
    }

    &[disabled] {
      opacity: 0;
    }
  }

  &:hover > button:not([disabled]) {
    opacity: 1;
  }

  img,
  video {
    border-style: none;
    vertical-align: top;
    border-radius: 2px;
    object-fit: contain;
    max-height: 100%;
    height: 100%;
  }
}

:global {
  .flickity-slider {
    & > * {
      top: 0;
      left: 0;
      max-width: 100%;
    }
  }
}
