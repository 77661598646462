@import '../../../variables';

.price-breakdown {
  background: var(--whoop-color-white);
  box-shadow: 0 0 24px 0 var(--whoop-color-black-alpha-100);
  border-radius: 8px;
  padding: 12px 24px;
  max-width: 350px;
}

.selected-option {
  font-family: var(--whoop-font-family-normal);
  text-transform: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-left: var(--spacing-xs-2);
  &li{
    margin-bottom: var(--spacing-xs-2);
  }
}

.fabric-description-details{
  padding-inline-start: 11px;
}

.btn-group {
  justify-content: left;
}

.fabric-btn {
  justify-content: left;

  button.fabric-btn {
    flex: none;
    padding: 0 28px;
  }
}

.product-fabric-selection {
  padding: 0 var(--spacing-xs-2) 0 0;
}

.fabric-label{
  text-transform: uppercase;
  font-size: var(--font-size-1);
}
