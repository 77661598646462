.wrapper {
  display: flex;
  flex-direction: row;
  background-color: var(--color-black-alpha-50);
  padding: 0 12px;
  border-radius: 8px;
  gap: 10px;

  p {
    font-family: var(--whoop-font-family-normal);
    font-size: var(--font-size-s);
    font-weight: 600;
  }
}
