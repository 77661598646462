@import 'src/components/layout/responsive.mixin';

.membership-card {
  display: flex;
  width: 464px;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  border-radius: 18px;
  border: 1px solid var(--tones-black-black-20, rgba(0, 0, 0, 0.2));
  background: var(--brand-white, #fff);
  @include less-than-md {
    width: 310px;
  }

  .membership-info {
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    flex-direction: column;
    > div {
      display: flex;
      justify-content: space-between;
    }
    .striked-price {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      span {
        margin: 0;
      }
      span:first-child {
        font-size: 16px;
      }
    }

    .main-info {
      color: var(--brand-black, --whoop-color-black);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.6px;
    }

    .sub-info {
      color: var(--color-black-alpha-500);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.36px;
    }

    .price-disclaimer {
      color: var(--brand-black, --whoop-color-black);
    }

    .membership-details {
      max-width: 300px;
      @include less-than-md {
        width: 200px;
      }
    }

    h2 {
      margin: 0;
    }
  }

  .badge {
    border-radius: 5px;
    margin: 0;
    padding: 5px 7px;
  }

  &:hover {
    cursor: pointer;
  }
}

.selected-card {
  border-color: var(--whoop-color-accent-blue);
}
