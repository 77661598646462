.dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.text {
  margin: 0 5px !important;
  font-size: 14px !important;
}

.dropdownButton {
  border: none;
  text-align: left;
  padding: 0px;
  color: var(--color-inactive-gray);
  height: 12px;
  display: contents;
  &:focus::before {
    transform: none !important;
    background-color: transparent !important;
  }
  &:hover::before {
    transform: none !important;
    background-color: transparent !important;
  }

  .icon {
    width: 12px;
    height: 12px;
    margin-left: auto;
    &:hover {
      color: var(--color-black);
    }
  }
}

.dropdownMenu {
  box-shadow: 0px 0px 8px var(--color-black-alpha-300);
  border-radius: 8px;
  position: absolute;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 300px;
  z-index: 1;
  top: 0;
  right: -10px;
  background: var(--color-white);

  .menuItem {
    align-items: center;
    padding: 3px 20px 1px 15px;
    gap: 8px;
    background-color: var(--color-white);
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &:hover {
      background: var(--color-black-alpha-50);
    }
  }
}
