@import 'src/styles/typography.mixin.scss';

.wrapper {
  width: 100%;
  margin-bottom: 20px;

  .label {
    color: var(--color-inactive-gray);
    margin: 20px 0 0 0;
  }

  .value {
    font-family: var(--whoop-font-family-medium);
    font-size: 20px;
    margin: 0;
    font-weight: 600;
  }

  .insideCol {
    margin: 0;
  }
}
