/* define overrideable variables */
.textarea {
  /* Set index used */
  --color-contrast: var(--color-black);
  --color-contrast-light: var(--color-black-alpha-300);
  --color-contrast-mid: var(--color-black-alpha-500);
  --color-disabled: var(--color-black-alpha-50);
  --color-focus: var(--color-black);
  --color-error: var(--color-red);

  --font-size: var(--font-size-m);
  --font-size-small: var(--font-size-s);
  --border-width: var(--line-width-sm);
  --padding-horizontal: var(--spacing-xs-4);
  --padding-vertical: var(--spacing-xs-3);
  --padding-small: 4px; // padding of small text
}

.textarea {
  --label-color: grey;
}

.textarea:focus-within {
  --label-color: transparent;
}

.character-count {
  text-align: right;
  font-family: var(--whoop-font-family-light);
  font-size: var(--font-size-small);
}

.error {
  color: red;
  font-family: var(--whoop-font-family-light);
  font-size: var(--font-size-small);
}

.sub-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 56px;
}

.sub-text:empty::before {
  content: "\200b"; /* unicode zero width space character so empty div retains min height*/
}

.textarea {
  textarea {
    // Need to remove the border width to make it consistent
    padding:
      calc(var(--padding-vertical) - var(--border-width))
      calc(var(--padding-horizontal) - var(--border-width));
    font-size: var(--font-size);
    letter-spacing: var(--font-letter-spacing-type);
    background: transparent;
    color: var(--color-contrast);
    box-sizing: border-box;
    line-height: 1em;
    width: 100%;
    text-align: left;
    font-family: var(--whoop-font-family-light);
    border: solid var(--border-width) var(--color-contrast-light);
    border-radius: 3px;
    resize: none;

    &:focus {
      outline: none;
      border-color: var(--color-contrast);
    }
    &:disabled {
      color: var(--color-contrast-light);
      background-color: var(--color-disabled);
    }
  }

  label {
    margin: 1em;
    position: absolute;
    font-family: var(--whoop-font-family-light);
    text-transform: none;
    font-weight: 400;
    pointer-events: none;
    color: var(--label-color);
    line-height: 1em;
    letter-spacing: var(--font-letter-spacing-normal);
    span {
      color: var(--color-error);
    }
  }
}
