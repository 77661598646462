@import '../../../variables';

.product-card {
  width: 328px;
  min-width: 328px;
  display: flex;
  flex-direction: column;

  &.clickable {
    cursor: pointer;
  }
}

.badge {
  position: absolute;
  margin: 12px;
  z-index: 1;
}

@media screen and (min-width: $screen-s-max) {
  .product-card:hover {
    .hover-banner {
      opacity: 1;
    }
  }
}

.hover-banner {
  opacity: 0;
  transition: 400ms ease;
}


.banner-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  animation: slideUp 200ms ease;

  & > * {
    width: 100%;
    margin: 0;
  }
}

.banner {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0 var(--spacing-xs);
  background-color: var(--color-pro-gold);
  text-transform: uppercase;
  font-family: var(--whoop-font-family-bold);
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
  animation: slideUp 200ms ease;
}
@keyframes slideUp {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}

.product-body {
  display: flex;
  flex-direction: column;
  flex: 1;

  .spacer {
    flex: 1;
  }

  .color-swatch {
    margin-right: auto;
  }
  .size-swatches {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .add-to-cart {
    float: right;
    padding: var(--spacing-xs-2) var(--spacing-xs-4);
  }
  .wp-exclusive {
    background-color: var(--color-pro-gold);
    border-color: var(--color-pro-gold);
  }

  .vert-bar {
    display: inline-block;
    height: 24px;
    width: 1px;
    vertical-align: middle;
    margin: 0 var(--spacing-xs);
    background: var(--color-black);
  }

  .product-details {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .content {
    margin-bottom: var(--spacing-xs-2);
  }

  .product-card-divider {
    border: solid var(--line-width-sm) var(--color-black-alpha-50);
    margin: var(--spacing-xs-4) 0;
  }

  .action-container {
    position: relative;
    align-self: flex-end;

    .size-guide {
      position: absolute;
      bottom: 100%;
      right: 0;
      padding: var(--spacing-xs-2);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.5px;
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  .product-header {
    margin: var(--spacing-xs-4) 0 var(--spacing-xs-2);
  }
}

.product-image {
  width: 100%;
  height: 400px;
  max-height: 100%;
  color: var(--color-black);
  position: relative;
  overflow: hidden;
  background: var(--color-gray-200);

  .slideshow {
    img, video {
      height: 100%;
      object-fit: cover;
    }
  }
  img, video {
    height: 100%;
    object-fit: cover;
  }
}


.overlay-badge {
  position: absolute;
  height: auto;
  bottom: 0;
  right: 0;
  margin: 12px;
}
