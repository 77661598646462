/* define default theme variables */
.button {
  /* Set index used */
  --color-primary: var(--color-branding-red);
  --color-primary-contrast: var(--color-white);
  --color-border: var(--color-contrast-light);
  --color-contrast: var(--color-black);
  --color-contrast-light: var(--color-gray-300);

  --color-ripple-alpha: var(--color-white-alpha-500);
  --color-hover-alpha: var(--color-white-alpha-300);

  --color-disabled: var(--color-gray-600);
  --color-disabled-light: var(--color-gray-300);
}

:global(.theme-enterprise) .button,
:global(.theme-enterprise).button {
  --color-primary: var(--color-strain-blue);
  --color-primary-contrast: var(--color-white);
  --color-border: var(--color-contrast-light);
  --color-contrast: var(--color-strain-blue);

  --color-ripple-alpha: var(--color-white-alpha-500);
  --color-hover-alpha: var(--color-white-alpha-300);

  --color-disabled: var(--color-gray-600);
  --color-disabled-light: var(--color-gray-300);
}

:global(.theme-join-flow) .button,
:global(.theme-join-flow).button {
  --color-primary: var(--color-black);
  --color-primary-contrast: var(--color-white);
  --color-border: var(--color-black);

  --color-ripple-alpha: var(--color-white-alpha-500);
  --color-hover-alpha: var(--color-white-alpha-300);

  --color-disabled: var(--color-join-flow-gray);
  --color-disabled-light: var(--color-black-alpha-400);
}

:global(.theme-dark) .button,
:global(.theme-dark).button {
  --color-primary: var(--color-white);
  --color-primary-contrast: var(--color-black);
  --color-border: var(--color-white);
  --color-contrast: var(--color-white);

  --color-ripple-alpha: var(--color-white-alpha-300);
  --color-hover-alpha: var(--color-white-alpha-500);

  --color-disabled: var(--color-gray-600);
  --color-disabled-light: var(--color-gray-300);
}

.button {
  font-family: var(--whoop-font-family-normal);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--font-letter-spacing);
  line-height: var(--spacing-xs-3);
  cursor: pointer;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: solid var(--line-width-md) transparent;
  border-radius: 3em;
  background-color: transparent;
  white-space: nowrap;
  transition: /* transitions between states */ background-color
      var(--anim-speed-normal) var(--anim-interp-default),
    color var(--anim-speed-normal) var(--anim-interp-default),
    border var(--anim-speed-fast) var(--anim-interp-default);

  &:focus {
    outline: none;
  }
  /* before acts as the hover */
  &:not([disabled]):before {
    content: '';
    pointer-events: none;
    position: absolute;
    border-radius: 50%;
    padding: 50%;
    transform: scale(0);
    transition: transform var(--anim-speed-normal) var(--anim-interp-default);
    transform-origin: center;
    background: var(--color-hover-alpha);
    left: -100%;
    top: -100%;
  }
  &:not([disabled]):focus:before,
  &:not([disabled]):hover:before {
    transform: scale(3.5);
  }
  &[disabled] {
    cursor: default;
  }

  & > svg {
    vertical-align: bottom;
  }
}

/* click ripples */
@keyframes ripple-anim {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
.ripple {
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  padding: 1%;
  transform: scale(0);
  animation: ripple-anim var(--anim-speed-slow) ease-out;
  background: var(--color-ripple-alpha);
}

.button-primary {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary);

  &[disabled] {
    color: var(--color-disabled-light);
    background-color: var(--color-disabled);
  }
}
.button-secondary {
  color: var(--color-primary);
  border-color: var(--color-border);
  --color-ripple-alpha: var(--color-black-alpha-100);
  --color-hover-alpha: var(--color-black-alpha-50);

  &[disabled] {
    color: var(--color-disabled);
  }
}
.button-normal {
  color: var(--color-contrast);
  border-color: var(--color-contrast);
  --color-ripple-alpha: var(--color-black-alpha-100);
  --color-hover-alpha: var(--color-black-alpha-50);

  &[disabled] {
    color: var(--color-disabled);
    border-color: var(--color-disabled);
  }
}
.button-link {
  color: var(--color-contrast);
  --color-ripple-alpha: var(--color-black-alpha-100);
  --color-hover-alpha: var(--color-black-alpha-50);
}

.button-blue {
  &[disabled] {
    color: var(--color-disabled);
  }
}
.button-survey {
  width: 100%;
  height: auto;
  border-color: var(--color-black);
  border-radius: 0.5em;
  margin: 0.4em 0;
  color: var(--color-black);
  background-color: var(--color-gray-400);
  font-family: var(--whoop-font-family-semibold);
  text-align: left;
  &[disabled] {
    color: var(--color-disabled);
    background-color: var(--color-light);
    border-color: var(--color-disabled);
  }
}

.button-small {
  font-size: var(--font-size-s);
  padding: var(--spacing-xs-2) var(--spacing-sm);
  height: 32px;
  & > svg {
    margin: calc(-1 * var(--spacing-xs) / 2) 0;
    width: var(--spacing-xs-4);
    height: var(--spacing-xs-4);
  }
}
.button-medium {
  font-size: var(--font-size-s);
  padding: var(--spacing-xs-3) var(--spacing-sm);
  height: 40px;
  & > svg {
    margin: calc(-1 * var(--spacing-xs) / 2) 0;
    width: var(--spacing-xs-4);
    height: var(--spacing-xs-4);
  }
}
.button-large {
  font-size: var(--whoop-font-size-m);
  line-height: var(--spacing-xs-5);
  padding: var(--spacing-xs-4) var(--spacing-sm-3);
  height: 56px;
  & > svg {
    margin: calc(-1 * var(--spacing-xs)) 0;
    margin-left: var(--spacing-xs);
    width: var(--spacing-sm-2);
    height: var(--spacing-sm-2);
  }
}

.button-survey {
  padding: 0.8em 1.25em;
}
