@import 'src/components/layout/responsive.mixin';

.slideshow {
  position: relative;
  height: 100%;

  & > div:first-child {
    height: 100%;
  }

  :global {
    .flickity-viewport {
      max-height: 100%;
      height: 100% !important; // Flickity tries to hijack this, but we want this height
    }

    .flickity-page-dots {
      opacity: 1;
      top: 73%;
      bottom: 0;
      .dot {
        background: var(--color-white);
      }
    }

    .flickity-prev-next-button {
      background: transparent;
      border-radius: 0;
      color: var(--color-inactive-gray);
      transition: 400ms;

      &:hover {
        color: var(--color-white);
        background: transparent !important;
      }

      @include less-than-md {
        opacity: 0;
      }

      .flickity-button-icon {
        width: 50%;
        height: 50%;
      }
    }

    .flickity-slider {
      & > * {
        top: 0;
        left: 0;
        max-width: 100%;
      }
    }
  }

  @include less-than-md {
    &:hover {
      & > :global(div.flickity-enabled) {
        & > :global(button.flickity-prev-next-button) {
          opacity: 1;
        }
      }
    }
  }

  & > button {
    position: absolute;
    height: 100%;
    top: 0;
    background: #ffffff50;
    opacity: 0;
    transition: opacity 400ms ease-in-out;

    @include less-than-md {
      display: none;
    }
  }

  &:hover > button:not([disabled]) {
    opacity: 1;
  }

  img,
  video {
    border-style: none;
    vertical-align: top;
    border-radius: 2px;
    object-fit: contain;
    max-height: 100%;
    height: 100%;
  }
}
