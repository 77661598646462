.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-gray-200);
  font-family: var(--whoop-font-family-semibold);
}

.header {
  display: flex;
  background-color: var(--color-background-darkblue);
  color: var(--color-gray-600);
  width: 100%;
  align-items: center;
}

.headerText {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 16.5px;
  font-weight: 700;
  color: var(--color-gray-600);
  letter-spacing: var(--font-letter-spacing);
  margin: 0;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url('../../assets/loginPageBackground.jpeg');
  font-family: var(--whoop-font-family-semibold);
  background-size: cover;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-gray-200);
    margin: 0;
  }
}

.inlineText {
  color: var(--color-gray-200);
  text-decoration: underline;
}

.secondaryText {
  color: var(--color-gray-700);
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
}

.logo {
  width: 26px;
  height: 26px;
  margin: 28px 20px 28px 28px;
}

.centerContainer {
  display: flex;
  flex-direction: column;
}

.button {
 margin-top: var(--spacing-sm);
 background-color: var(--color-strain-blue);
 color: var(--color-white);
}

.errorContainer {
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.errorText {
  color: var(--color-red);
}

.errorHelp {
  margin-left: 2px;
}
