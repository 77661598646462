
.engraving-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: calc(min(400px, 100%));

  & > div {
    margin: 12px 8px;
  }
}

.icon-selector {
  text-align: center;
  max-height: 200px;
  overflow-y: auto;
  border: solid 1px var(--whoop-color-black-alpha-300);
  border-radius: 3px;
  transition: max-height 400ms ease;

  .selector-text {
    cursor: pointer;
    text-align: left;
    padding: 12px 16px;
    font-family: var(--whoop-font-family-light);
    color: var(--whoop-color-gray-500);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.selected {
      color: var(--whoop-color-black);
    }

    // drop down icon
    & > svg {
      width: 12px;
      height: 12px;
    }
  }

  // selectable icons
  & > svg {
    padding: 12px;
    box-sizing: content-box;
    color: var(--whoop-color-black-alpha-500);
    cursor: pointer;
    transition: color 200ms;
    &.selected {
      color: var(--whoop-color-strain-blue);
    }
    &:not(.selected):hover {
      color: var(--whoop-color-black);
    }
  }
}

.icon-preview {
  margin: 12px auto;
  width: 64px;
  height: 64px;
}

.engraving {
  margin-bottom: 16px;
}

.toggle {
  margin-bottom: 16px;
}


.engraving-selector {
  margin-bottom: var(--spacing-sm-3);

  .error {
    margin: 0 10px;
    font-size: 14px;
    font-family: var(--whoop-font-family-bold);
    color: var(--whoop-color-red);
  }
}



$default-engraving-color: #958a7e;
.preview-container {
  position: relative;
  max-height: 100%;
  height: 100%;

  .preview {
    position: absolute;
    left: 0;
    top: 0;

    text {
      fill: $default-engraving-color;
      text-anchor: middle;
      font-weight: bold;
    }

    .icon {
      color: $default-engraving-color;
    }
  }
}

