.referrer-section {
  background-color: var(--whoop-color-white);
  border-radius: 18px;
  padding: 12px 0px 12px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  .referrer-image {
    border-radius: 50%;
  }
  .content {
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -0.54px;
  }
  .title {
    text-transform: uppercase;
    font-weight: 700;
  }
}
