@import '../../../variables';

.product-header {
  position: relative;
  text-align: left;

  @media screen and (max-width: $screen-s-max) {
    &.header-large {
      text-align: center;
    }
  }

  .header-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  &.header-large .header-group {
    @media screen and (max-width: $screen-s-max) {
      justify-content: center;
    }
  }

  .sub-title {
    margin: 0 0 0 var(--spacing-xs-3);
    font-size: 16px;
    line-height: 1em;
    color: var(--whoop-color-recovery-blue);
  }
  &.header-small .sub-title {
    font-size: 12px;
  }
  &.header-medium .sub-title {
    font-size: 14px;
  }

  &.header-small h1 {
    font-size: 12px;
    letter-spacing: 1.4px;
    margin: 0 var(--spacing-xs-2) 0 0;
  }
  &.header-medium h1 {
    font-size: 16px;
    margin: 0 var(--spacing-xs-2) 0 0;
  }
  h1 {
    text-transform: uppercase;
    font-size: var(--spacing-sm-4);
    margin: 0 0 var(--spacing-xs-2);
    line-height: 1em;

    &.fancy {
      background: linear-gradient(90deg, #5E22DC 0.53%, #0D95B4 97.4%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .badge {
    position: absolute;
    bottom: calc(100% + 8px);

    @media screen and (max-width: $screen-s-max) {
      margin: auto;
      top: -2rem;
      left: 0;
      right: 0;
    }
  }
}



.price-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .header-large & {
    @media screen and (max-width: $screen-s-max) {
      justify-content: center;
    }
  }

  .price {
    font-size: 18px;
    font-family: var(--dinpro-font-family-bold);
    letter-spacing: 1px;

    .header-small & {
      font-size: 12px;
    }
    .header-medium & {
      font-size: 16px;
    }
  }

  .with-whoop-pro-price {
    display: flex;
    margin-left: var(--spacing-xs-3);
    align-items: center;
    justify-content: center;

    span:first-child {
      color: var(--whoop-color-gray-700);
      font-size: var(--spacing-xs-3);
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-weight: 600;
    }

    svg { // Pro logo
      color: black;
      width: 1.2em;
      height: 1.2em;
      margin-left: var(--spacing-xs);
    }
  }
}
