/* define overrideable variables */
.footer {
  /* Set index used */
  --color-background: var(--color-black);
  --color-contrast: var(--color-white);
  --color-contrast-light: var(--color-white-alpha-500);
}

.footer {
  font-family: var(--whoop-font-family-normal);
  width: 100%;
  background-color: var(--color-background-blue);
  color: var(--color-contrast);
  box-sizing: border-box;
  padding: var(--spacing-md) var(--spacing-sm-3) 0;

  /* override default html tag behavior */
  a {
    color: var(--color-contrast);
    cursor: pointer;
    text-decoration: none;

    a:visited {
      color: var(--color-contrast);
    }
  }
  li {
    list-style: none;
  }
  ul {
    margin: 0;
    padding-left: 0;
  }
  p {
    margin: 0;
    line-height: calc(1em + var(--spacing-xs-2));
  }

  h2 {
    font-size: var(--font-size-s);
    text-transform: uppercase;
    letter-spacing: var(--font-letter-spacing);
    margin-bottom: var(--spacing-xs-4);
  }
  h2.clickable {
    cursor: pointer;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media screen and (max-width: 480px) {
    justify-content: initial;
  }


  .logo {
    margin-right: var(--spacing-xs-4);
    margin-top: calc(var(--spacing-xs-4) * -1);
  }

  .about {
    flex-basis: 400px;
  }
  .link-groups {
    display: flex;
    flex-wrap: wrap;
  }
  .about-text,
  .link {
    font-family: var(--whoop-font-family-light);
    font-size: var(--font-size-s);
  }
  .about,
  .link-group {
    margin-bottom: var(--spacing-sm-3);
    padding-left: var(--spacing-sm-3);
    padding-right: var(--spacing-sm-3);
  }
}

.policy-links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  padding-bottom: var(--spacing-xs-4);

  span,
  a {
    font-family: var(--whoop-font-family-light);
    font-size: var(--font-size-xs);
    text-transform: uppercase;
    letter-spacing: var(--font-letter-spacing);
    padding: 0 var(--spacing-xs-3);

    @media screen and (max-width: 480px) {
      padding: var(--spacing-xs-3) var(--spacing-xs);
    }
  }

  span {
    color: var(--color-contrast-light);
  }
}

@media screen and (max-width: 480px) {
  .container {
    justify-content: initial;
  }
}
