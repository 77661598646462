// make color map to be exported
$colors: (
  'black': #000000,
  'white': #FFFFFF,
  'branding-red': #FF0026,
  'red': #D7001E,
  'yellow': #FFDE00,
  'recovery-yellow': #F4BF00,
  'trend-yellow': #FFA722,
  'focus-yellow': #FFEB62,
  'notes-yellow': #FFFBE2,
  'green': #19EC06,
  'teal': #00F19F,
  'strain-blue': #0093E7,
  'recovery-blue': #67AEE6,
  'notification-blue': #98D2FF,
  'sleep-blue': #7BA1BB,
  'pro-gold': #d0a74e,
  'inactive-gray': #999999,
  'disabled-gray': #A6A6A6,
  'dark-gray': #27333A,
  'background-blue': #1A2227,
  'background-darkblue': #101518,
  'magenta': #A47BBD,
  'hr-zone-red': #FF6422,
  'hr-zone-orange': #FCAC5D,
  'hr-zone-teal': #59B996,
  'hr-zone-blue': #479AC2,
  'hr-zone-grey': #ADC2CD,
  'sleep-zone-purple': #A4A3F1,
  'sleep-zone-magenta': #AC5AED,
  'sleep-zone-pink': #FA95FA,
  'onboarding-action': #CB4ED8,
  'light-pink': #FFDDE2,
  'accent-blue': #31A2B1,
  'join-flow-gray': #F3F5F9,

  'gray-700': #969696,
  'gray-600': #A6A6A6,
  'gray-500': #C8C8C8,
  'gray-400': #DCDCDC,
  'gray-300': #EBEBEB,
  'gray-200': #F5F5F5,
  'gray-100': #FAFAFA,

  'white-alpha-900': rgba(255, 255, 255, .9),
  'white-alpha-800': rgba(255, 255, 255, .8),
  'white-alpha-700': rgba(255, 255, 255, .7),
  'white-alpha-600': rgba(255, 255, 255, .6),
  'white-alpha-500': rgba(255, 255, 255, .5),
  'white-alpha-400': rgba(255, 255, 255, .4),
  'white-alpha-300': rgba(255, 255, 255, .3),
  'white-alpha-200': rgba(255, 255, 255, .2),
  'white-alpha-100': rgba(255, 255, 255, .1),
  'white-alpha-50': rgba(255, 255, 255, .05),

  'black-alpha-900': rgba(0, 0, 0, .9),
  'black-alpha-800': rgba(0, 0, 0, .8),
  'black-alpha-700': rgba(0, 0, 0, .7),
  'black-alpha-600': rgba(0, 0, 0, .6),
  'black-alpha-500': rgba(0, 0, 0, .5),
  'black-alpha-400': rgba(0, 0, 0, .4),
  'black-alpha-300': rgba(0, 0, 0, .3),
  'black-alpha-200': rgba(0, 0, 0, .2),
  'black-alpha-100': rgba(0, 0, 0, .1),
  'black-alpha-50': rgba(0, 0, 0, .05),

  'green-alpha-100': rgba(25, 236, 6, 0.1),
  'green-alpha-200': rgba(25, 236, 6, 0.2),
  'green-alpha-300': rgba(25, 236, 6, 0.3),
  'green-alpha-400': rgba(25, 236, 6, 0.4),
  'green-alpha-500': rgba(25, 236, 6, 0.5),
  'green-alpha-600': rgba(25, 236, 6, 0.6),
  'green-alpha-700': rgba(25, 236, 6, 0.7),
  'green-alpha-800': rgba(25, 236, 6, 0.8),
  'green-alpha-900': rgba(25, 236, 6, 0.9),

  'yellow-alpha-100': rgba(255, 222, 0, 0.1),
  'yellow-alpha-200': rgba(255, 222, 0, 0.2),
  'yellow-alpha-300': rgba(255, 222, 0, 0.3),
  'yellow-alpha-400': rgba(255, 222, 0, 0.4),
  'yellow-alpha-500': rgba(255, 222, 0, 0.5),
  'yellow-alpha-600': rgba(255, 222, 0, 0.6),
  'yellow-alpha-700': rgba(255, 222, 0, 0.7),
  'yellow-alpha-800': rgba(255, 222, 0, 0.8),
  'yellow-alpha-900': rgba(255, 222, 0, 0.9),

  'pro-gold-alpha-100': rgba(208, 167, 78, 0.1),
  'pro-gold-alpha-200': rgba(208, 167, 78, 0.2),
  'pro-gold-alpha-300': rgba(208, 167, 78, 0.3),
  'pro-gold-alpha-400': rgba(208, 167, 78, 0.4),
  'pro-gold-alpha-500': rgba(208, 167, 78, 0.5),
  'pro-gold-alpha-600': rgba(208, 167, 78, 0.6),
  'pro-gold-alpha-700': rgba(208, 167, 78, 0.7),
  'pro-gold-alpha-800': rgba(208, 167, 78, 0.8),
  'pro-gold-alpha-900': rgba(208, 167, 78, 0.9),

  'red-alpha-100': rgba(255, 0, 38, 0.1),
  'red-alpha-200': rgba(215, 0, 30, 0.2),
  'red-alpha-300': rgba(215, 0, 30, 0.3),
  'red-alpha-400': rgba(215, 0, 30, 0.4),
  'red-alpha-500': rgba(215, 0, 30, 0.5),
  'red-alpha-600': rgba(215, 0, 30, 0.6),
  'red-alpha-700': rgba(215, 0, 30, 0.7),
  'red-alpha-800': rgba(215, 0, 30, 0.8),
  'red-alpha-900': rgba(215, 0, 30, 0.9),

  'strain-blue-alpha-100': rgba(0, 147, 231, 0.1),
  'strain-blue-alpha-200': rgba(0, 147, 231, 0.2),
  'strain-blue-alpha-300': rgba(0, 147, 231, 0.3),
  'strain-blue-alpha-400': rgba(0, 147, 231, 0.4),
  'strain-blue-alpha-500': rgba(0, 147, 231, 0.5),
  'strain-blue-alpha-600': rgba(0, 147, 231, 0.6),
  'strain-blue-alpha-700': rgba(0, 147, 231, 0.7),
  'strain-blue-alpha-800': rgba(0, 147, 231, 0.8),
  'strain-blue-alpha-850': rgba(0, 147, 231, 0.85),
  'strain-blue-alpha-900': rgba(0, 147, 231, 0.9),

  'strain-blue-strenuous': rgb(3, 129, 202),
  'strain-blue-moderate': rgb(6, 111, 171),
  'strain-blue-light': rgb(10, 87, 131),

  'sleep-blue-alpha-100': rgba(123, 161, 187, 0.1),
  'sleep-blue-alpha-200': rgba(123, 161, 187, 0.2),
  'sleep-blue-alpha-300': rgba(123, 161, 187, 0.3),
  'sleep-blue-alpha-400': rgba(123, 161, 187, 0.4),
  'sleep-blue-alpha-500': rgba(123, 161, 187, 0.5),
  'sleep-blue-alpha-600': rgba(123, 161, 187, 0.6),
  'sleep-blue-alpha-700': rgba(123, 161, 187, 0.7),
  'sleep-blue-alpha-800': rgba(123, 161, 187, 0.8),
  'sleep-blue-alpha-900': rgba(123, 161, 187, 0.9),

  'sleep-blue-sufficient': rgb(82, 108, 124),
  'sleep-blue-poor': rgb(52, 68, 78),

  'magenta-alpha-100': rgba(164, 123, 189, 0.1),
  'magenta-alpha-200': rgba(164, 123, 189, 0.2),
  'magenta-alpha-300': rgba(164, 123, 189, 0.3),
  'magenta-alpha-400': rgba(164, 123, 189, 0.4),
  'magenta-alpha-500': rgba(164, 123, 189, 0.5),
  'magenta-alpha-600': rgba(164, 123, 189, 0.6),
  'magenta-alpha-700': rgba(164, 123, 189, 0.7),
  'magenta-alpha-800': rgba(164, 123, 189, 0.8),
  'magenta-alpha-900': rgba(164, 123, 189, 0.9),

  'focus-alpha-100': rgba(255, 235, 98, 0.1),
  'focus-alpha-150': rgba(255, 235, 98, 0.15),
  'focus-alpha-200': rgba(255, 235, 98, 0.2),
  'focus-alpha-300': rgba(255, 235, 98, 0.3),
  'focus-alpha-400': rgba(255, 235, 98, 0.4),
  'focus-alpha-500': rgba(255, 235, 98, 0.5),
  'focus-alpha-600': rgba(255, 235, 98, 0.6),
  'focus-alpha-700': rgba(255, 235, 98, 0.7),
  'focus-alpha-800': rgba(255, 235, 98, 0.8),
  'focus-alpha-900': rgba(255, 235, 98, 0.9),


  'green-trend-alpha-100': rgba(0, 241, 159, 0.1),
  'green-trend-alpha-150': rgba(0, 241, 159, 0.15),
  'green-trend-alpha-200': rgba(0, 241, 159, 0.2),
  'green-trend-alpha-300': rgba(0, 241, 159, 0.3),
  'green-trend-alpha-400': rgba(0, 241, 159, 0.4),
  'green-trend-alpha-500': rgba(0, 241, 159, 0.5),
  'green-trend-alpha-600': rgba(0, 241, 159, 0.6),
  'green-trend-alpha-700': rgba(0, 241, 159, 0.7),
  'green-trend-alpha-800': rgba(0, 241, 159, 0.8),
  'green-trend-alpha-900': rgba(0, 241, 159, 0.9),


  'yellow-trend-alpha-100': rgba(255, 167, 34, 0.1),
  'yellow-trend-alpha-150': rgba(255, 167, 34, 0.15),
  'yellow-trend-alpha-200': rgba(255, 167, 34, 0.2),
  'yellow-trend-alpha-300': rgba(255, 167, 34, 0.3),
  'yellow-trend-alpha-400': rgba(255, 167, 34, 0.4),
  'yellow-trend-alpha-500': rgba(255, 167, 34, 0.5),
  'yellow-trend-alpha-600': rgba(255, 167, 34, 0.6),
  'yellow-trend-alpha-700': rgba(255, 167, 34, 0.7),
  'yellow-trend-alpha-800': rgba(255, 167, 34, 0.8),
  'yellow-trend-alpha-900': rgba(255, 167, 34, 0.9),
);

:export {
  @each $colorName, $color in $colors {
      #{$colorName}: $color;
  }
}

:global(html) {
  @each $colorName, $color in $colors {
    #{'--whoop-color-' + unquote($colorName)}: $color;
    #{'--color-' + unquote($colorName)}: $color;
  }
}
