$screen-m: 1008px;

.gift-message-modal {
  padding: 32px 24px;
  font-size: 14px;
  line-height: 18px;

  @media (min-width: $screen-m) {
    padding: 32px 40px;
  }

  .header-section {
    text-align: center;
    margin-bottom: 24px;

    .icon-container {
      color: #fff;
      background-color: #000;
      width: 95px;
      height: 95px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px auto 30px;

      svg {
        height: 46px;
        width: 46px;
      }
    }

    .subheader {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.9);

      a {
        text-decoration: none;
        color: var(--color-strain-blue);
        font-weight: 600;
      }
    }
  }

  .form-section {
    .field-group {
      padding: 5px 0;

      h3 {
        margin-top: 12px;
        margin-bottom: 8px;
      }
    }

    .field-row {
      display: flex;
      width: 100%;
      gap: 8px;

      input, textarea {
        font-size: 14px;
      }

      .single-input {
        width: 100%;
      }

      &.gift-message-input {
        div {
          width: 100%;
        }
      }
    }
  }

  .delivery-subtext {
    font-weight: 600;

    &.purchaser-delivery-subtext {
      padding: 8px 0;
    }
  }

  .delivery-section {
    margin: 24px 0 0;
    padding-top: 24px;
    border-top: 1px solid #C8C8C8;

    .delivery-subtext {
      margin-bottom: 24px;
    }

    .shipping-disclaimer {
      font-weight: 600;
      opacity: 0.5;
    }

    .delivery-date-picker :global(.react-date-picker) {
      width: 100%;
    }
  }

  .button-section {
    margin-top: 36px;

    button {
      width: 100%;
      height: 40px;
      border-radius: 60px;
      margin: 6px auto;
    }

    .confirm-button button:enabled {
      background: #FF0026;
    }
  }

  .error-section {
    color: #ff0026;
    font-weight: 600;
    padding-top: 5px;
    margin-bottom: -20px;
  }
}
