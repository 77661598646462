@import '../../../variables';

.product-layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: var(--spacing-md-2) var(--spacing-sm-5);
  @media screen and (max-width: $screen-s-max) {
    padding: 0;
    display: block;
  }

  :global(.flickity-viewport){
    img{
      min-height: 100%;
    }
  }

  .product-preview {
    flex: 1;
    position: sticky;
    position: -webkit-sticky; /* Required for Safari */
    top: var(--spacing-md-2);
    z-index: 1;
    height: 100%;
    @media screen and (max-width: $screen-s-max) {
      top: 0;
      padding-top: var(--spacing-sm);
      background: white;

      &.scrolled {
        box-shadow: 0 0 24px 0 var(--whoop-color-black-alpha-200);
      }
    }

    &.no-scroll {
      @media screen and (max-width: $screen-s-max) {
        position: unset;
      }
    }

    .slideshow {
      margin: auto;
      width: 500px;
      height: 500px;
      max-width: 100vw;
      max-height: 100vw;
      transition: height 400ms ease;
      transition-delay: 100ms;

      @media screen and (max-width: $screen-s-max) {
        width: unset;
        :global(.flickity-viewport){
          min-height: 100%;
        }
      }
    }

    @media screen and (max-width: $screen-s-max) {
      .not-scrolled {
        border-bottom: 16px solid transparent;
        border-image: linear-gradient(to top, transparent, var(--color-black-alpha-200) 1px);
        border-image-slice: 1;
        transition: opacity 400ms ease;
        transition-delay: 100ms;
        opacity: 0;
      }

      .scroll-fade{
        opacity: 1;
      }

      .scroll-hide{
        background-color: var(--color-white);
        padding-top: 4px;
        padding-bottom: 4px;
      }

      .image{
        height: 100%;
        width: 100%;
        transition: height 400ms ease;
        transition-delay: 100ms;
      }

      .scroll-image{
        height: 30vh;
        width: 100%;
      }
    }
  }

  .product-info {
    flex: .8;
    padding: 0 0 0 var(--spacing-sm-4);
    max-width: 600px;

    @media screen and (max-width: $screen-s-max) {
      padding: var(--spacing-sm) var(--spacing-xs-4);
      max-width: unset;
    }

    .price-breakdown-button {
      margin: auto;
      @media screen and (min-width: $screen-m-min) {
        margin: unset;
      }
    }
  }
}

.overlay-badge {
  position: absolute;
  height: auto;
  bottom: 0;
  right: 0;
}

.mobile-only {
  display: none;

  @media screen and (max-width: $screen-s-max) {
    display: unset;
  }
}

.desktop-only {
  display: unset;

  @media screen and (max-width: $screen-s-max) {
    display: none;
  }
}
