@import 'styles/colors.module';
@import 'styles/spacing.module';
@import 'styles/line-widths.module';
@import 'styles/typography.mixin';
@import url("https://use.typekit.net/nnt8fft.css");
/*
   Fonts
*/
// ProximaNova by family
@font-face {
  font-family: ProximaNova-Light;
  src: url('https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3');
}
@font-face {
  font-family: ProximaNova-Regular;
  src: url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3');
}
@font-face {
  font-family: ProximaNova-Medium;
  src: url('https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3');
}
@font-face {
  font-family: ProximaNova-Semibold;
  src: url('https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3');
}
@font-face {
  font-family: ProximaNova-Bold;
  src: url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3');
}

// ProximaNova by Weight
@font-face {
  font-family: ProximaNova-Regular;
  font-weight: 300;
  // light
  src: url('https://use.typekit.net/af/1be3c2/00000000000000007735e606/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3');
}
@font-face {
  font-family: ProximaNova-Regular;
  font-weight: 400;
  src: url('https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3');
}
@font-face {
  font-family: ProximaNova-Regular;
  font-weight: 500;
  src: url('https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3');
}
@font-face {
  font-family: ProximaNova-Regular;
  font-weight: 600;
  src: url('https://use.typekit.net/af/78aca8/00000000000000007735e60d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3');
}
@font-face {
  font-family: ProximaNova-Regular;
  font-weight: 700;
  src: url('https://use.typekit.net/af/2555e1/00000000000000007735e603/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3');
}

// DINPro by family
@font-face {
  font-family: DINPro-Light;
  src: url('fonts/DINPro-Light.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Regular;
  src: url('fonts/DINPro-Regular.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Medium;
  src: url('fonts/DINPro-Medium.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Bold;
  src: url('fonts/DINPro-Bold.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Black;
  src: url('fonts/DINPro-Black.otf') format('opentype');
}
// DINPro by Weight
@font-face {
  font-family: DINPro-Regular;
  font-weight: 200;
  src: url('fonts/DINPro-Light.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Regular;
  font-weight: 300;
  src: url('fonts/DINPro-Regular.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Regular;
  font-weight: 500;
  src: url('fonts/DINPro-Medium.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Regular;
  font-weight: 700;
  src: url('fonts/DINPro-Bold.otf') format('opentype');
}
@font-face {
  font-family: DINPro-Regular;
  font-weight: 900;
  src: url('fonts/DINPro-Black.otf') format('opentype');
}

html {
  // all fonts need the `Helvetica, ...` fallback to prevent loading serif fonts first
  --whoop-font-family-light: 'ProximaNova-Light', 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
  --whoop-font-family-normal: 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
  --whoop-font-family-medium: 'ProximaNova-Medium', 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
  --whoop-font-family-semibold: 'ProximaNova-Semibold', 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
  --whoop-font-family-bold: 'ProximaNova-Bold', 'ProximaNova-Regular', Helvetica, Arial, sans-serif;

  --dinpro-font-family-light: 'DINPro-Light', 'ProximaNova-Light', Helvetica, Arial, sans-serif;
  --dinpro-font-family-normal: 'DINPro-Regular', 'ProximaNova-Regular', Helvetica, Arial, sans-serif;
  --dinpro-font-family-medium: 'DINPro-Medium', 'ProximaNova-Medium', Helvetica, Arial, sans-serif;
  --dinpro-font-family-bold: 'DINPro-Bold', 'ProximaNova-Bold', Helvetica, Arial, sans-serif;
  --dinpro-font-family-black: 'DINPro-Black', 'ProximaNova-Bold', Helvetica, Arial, sans-serif;

  /*
    Animation
   */
  --anim-speed-instant: 100ms;
  --anim-speed-fast: 200ms;
  --anim-speed-normal: 400ms;
  --anim-speed-slow: 1000ms;
  --anim-speed-very-slow: 2000ms;
  --anim-interp-default: ease-in-out;

  /* Legacy */
  --font-size-xs: 9px;
  --font-size-s: 12px;
  --whoop-font-size-m: 14px;
  --font-size-m: 16px;
  --font-size-l: 24px;
  --font-size-xl: 48px;
  --font-letter-spacing: 0.12em;
  --font-letter-spacing-normal: 0.01em;
  --font-letter-spacing-type: 0.03em; /* need extra spacing when typing */


  // root font size
  --font-size-root: 16px;
}

body {
  font-size: var(--font-size-root);
  font-family: var(--whoop-font-family-normal);
}

/*
  For media queries in responsive layouts reference this:

    Laptop/monitor as default
      phone:
          @media screen and (max-width: 641px) { override styles here }
      tablet:
          @media screen and (max-width: 1140px) { override styles here }

    Mobile as default
      tablet:
          @media screen and (min-width: 641px) { override styles here }
      laptop/monitor:
          @media screen and (min-width: 1140px) { override styles here }
*/

/**
  Legacy Typography
 */
:global {
  .whoop-ui {
    /* set default font */
    font-family: var(--whoop-font-family-normal);

    h1 {
      font-family: var(--whoop-font-family-bold);
      font-size: 24px;
      letter-spacing: 2.4px;
      line-height: 29px;
      text-transform: uppercase;
    }

    h2 {
      font-family: var(--whoop-font-family-bold);
      font-size: 18px;
      letter-spacing: 1.8px;
      line-height: 22px;
      text-transform: uppercase;
    }

    h3 {
      font-family: var(--whoop-font-family-bold);
      font-size: 15px;
      letter-spacing: 1.5px;
      line-height: 18px;
      text-transform: uppercase;
    }

    h4 {
      font-family: var(--whoop-font-family-bold);
      font-size: 13px;
      letter-spacing: 1.3px;
      line-height: 16px;
      text-transform: uppercase;
    }

    h5 {
      font-family: var(--whoop-font-family-bold);
      font-size: 11px;
      letter-spacing: 1.1px;
      line-height: 13px;
      text-transform: uppercase;
    }

    h6 {
      font-family: var(--whoop-font-family-bold);
      font-size: 10px;
      letter-spacing: 1px;
      line-height: 12px;
      text-transform: uppercase;
    }

    p {
      font-family: var(--whoop-font-family-normal);
      font-size: 16px;
      line-height: 20px;
    }

    a:not(.no-color) {
      color: var(--color-red);
      text-decoration: none;
    }
  }

  // Tooltips
  @media not all, (hover: hover) {
    [data-tooltip] {
      position: relative;

      &::before {
        content: attr(data-tooltip);
        position: absolute;
        bottom: 100%;
        left: calc(50% - var(--spacing-xs-3));
        z-index: 1;

        padding: var(--spacing-xs-3);
        background: var(--color-white);
        color: var(--color-black);
        border-radius: 4px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.25);

        font-size: var(--spacing-xs-3);
        font-family: var(--whoop-font-normal);
        font-weight: 600;
        text-transform: none;
        line-height: 15px;
        letter-spacing: .02em;
        white-space: nowrap;

        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
      }

      &:hover::before {
        opacity: 1;
        visibility: visible;
      }
    }
    [data-tooltip-side=rigth] {
      right: 0;
    }
  }
}

@mixin defaultTypeStyle {
  &.font-normal {
    font-family: var(--whoop-font-family-normal);
  }
  &.font-bold {
    font-family: var(--whoop-font-family-bold);
  }
  &.font-semibold {
    font-family: var(--whoop-font-family-semibold);
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.transform-none {
    text-transform: none;
  }
}

:global {
  .whoop-typography {
    // variables are located in styles/typography.mixin.scss

    h1 {
      @include h1;
      &.t1 {
        @include t1;
      }
      &.t2 {
        @include t2;
      }
    }
    h2 {
      @include h2;
    }

    h3 {
      @include h3;
    }

    h4 {
      @include h4;
    }

    h5 {
      @include h5;
    }

    h6 {
      @include h6;
    }

    // Text, Number, and Button styles can be applied to any element except the heading elements which have their own styling
    :not(h1,h2,h3,h4,h5,h6) {
      // text styles
      &.p1 {
        @include p1;
      }
      &.p2 {
        @include p2;
      }
      &.p3 {
        @include p3;
      }
      &.p4 {
        @include p4;
      }
      &.p5 {
        @include p5;
      }
      &.c1 {
        @include c1;
      }
      &.c2 {
        @include c2;
      }

      // Number styles
      &.n1 {
        @include n1;
      }
      &.n2 {
        @include n2;
      }
      &.n3 {
        @include n3;
      }
      &.n4 {
        @include n4;
      }
      &.n5 {
        @include n5;
      }
      &.n6 {
        @include n6;
      }

      // Button style
      &.b1 {
        @include b1;
      }
    }
  }
}
