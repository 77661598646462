.checkbox {
  --background-color: transparent;
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 2.5em;
  --color: black;
  --local-border-radius: 50%;
  cursor: pointer;
}

.checkbox label {
  background-color: var(--background-color);
  border: var(--checkbox-border-size) solid var(--color);
  border-radius: var(--local-border-radius);
  cursor: pointer;
  height: var(--checkbox-size);
  left: 0;
  position: absolute;
  top: 0;
  width: var(--checkbox-size);
}

.checkbox label:after {
  border: var(--check-thickness) solid var(--color-white);
  border-top: none;
  border-right: none;
  content: "";
  height: var(--check-left);
  left: var(--check-left-displacement);
  opacity: 0;
  position: absolute;
  top: var(--check-top-displacement);
  transform: rotate(-45deg);
  width: var(--check-right);
}

.checkbox input[type="checkbox"] {
  visibility: hidden;
}
.checkbox input[type="checkbox"]:checked + label {
  background-color: var(--color);
  border-color: var(--color);
}
.checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.checkbox-square {
  --local-border-radius: var(--border-radius);
}

.checkbox-small {
  --checkbox-border-size: 0.1875em;
  --check-thickness: 0.1875em;
  --check-left: 0.375em;
  --check-right: 0.75em;
  --checkbox-size: 1.5em;
  --check-left-displacement: 0.3em;
  --check-top-displacement: 0.375em;
  width: 1.875em;
  height: 1.875em;
}
.checkbox-large {
  --checkbox-border-size: 0.25em;
  --check-thickness: 0.25em;
  --check-left: 0.5em;
  --check-right: 1em;
  --checkbox-size: 2em;
  --check-left-displacement: 0.4em;
  --check-top-displacement: 0.5em;
  width: 2.5em;
  height: 2.5em;
}

.disabled {
  --color: var(--color-disabled-gray);
  cursor: default;
}
