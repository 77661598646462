.inputContainer {
    position: relative;
}

.resultContainer {
    border: 1px solid #E0E0E0;
    position: absolute;
    border: solid var(--line-width-sm) var(--color-black-alpha-300);
    border-radius: 3px;
    background-color: white;
    max-height: 300px;
    overflow: auto;
    width: 100%;
}

.resultItemContainer {
    padding: 8px 15px;
    cursor: pointer;
    user-select: none;
    font-size: var(--font-size-0);
    width: 100%;

    &:hover, &.active {
        background-color: #E0E0E0;
    }
}
