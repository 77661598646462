.swatch-container {
  width: 100%;
  font-family: var(--whoop-font-family-bold);
  font-size: var(--spacing-xs-3);
}

.swatch {
  padding: var(--swatch-padding);
  display: inline-block;

  & > * {
    cursor: pointer;
  }

  input[type='radio'] {
    width: var(--swatch-size);
    height: var(--swatch-size);
    margin: 0;
    opacity: 0;
    position: absolute;
  }

  & > label {
    cursor: pointer;
    border-radius: 50%;
    line-height: var(--swatch-size);
    width: var(--swatch-size);
    height: var(--swatch-size);
    margin: 0;
    text-transform: uppercase;
    box-sizing: border-box;
    display: block;
    text-align: center;
    position: relative;
    border: none;
    box-shadow: inset 0 0 0 1px var(--color-black-alpha-200);
    --selection-border-width: var(--line-width-md);

    // circle border around hovered or selected
    &:after {
      content: '';
      position: absolute;
      border: solid var(--selection-border-width) var(--color-gray-400);
      border-radius: 50%;
      // this acts as the half the size of the circle
      padding: calc(50% + var(--selection-border-width) + 1px);
      left: calc(var(--selection-border-width) * -1 - var(--selection-border-width) - 1px);
      top: calc(var(--selection-border-width) * -1 - var(--selection-border-width) - 1px);
      opacity: 0;
      transition: opacity var(--anim-speed-fast) var(--anim-interp-default);
    }

    // hovered
    &:hover:after {
      opacity: 1;
      border-color: var(--color-black-alpha-200);
    }
  }

  // selected
  input[type='radio']:checked + label:after {
    opacity: 1;
    border-color: var(--color-black);
  }

  input[type='radio']:disabled + label {
    cursor: not-allowed;
    ::before, ::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      transform-origin: center;
      transform: rotate(45deg);
    }

    ::after {
      left: calc(50% - var(--line-width-sm));
      background: var(--color-black);
      width: var(--line-width-md);
    }

    ::before {
      left: calc(50% - var(--line-width-md));
      background: var(--color-white-alpha-500);
      width: var(--line-width-lg);
    }
  }

  .disabled + label {
    ::before, ::after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      transform-origin: center;
      transform: rotate(45deg);
    }

    ::after {
      left: calc(50% - var(--line-width-sm));
      background: var(--color-black);
      width: var(--line-width-md);
    }

    ::before {
      left: calc(50% - var(--line-width-md));
      background: var(--color-white-alpha-500);
      width: var(--line-width-lg);
    }
  }

  &.text-swatch {
    & > label {
      border-color: var(--color-black-alpha-200);
    }

    input[type='radio']:checked + label {
      background-color: var(--color-black);
      color: var(--color-white);
    }
  }
}

.swatch-small {
  --swatch-size: var(--spacing-sm);
  --swatch-padding: calc(var(--spacing-xs-2) / 2);
}

.swatch-medium {
  --swatch-size: var(--spacing-sm-3);
  --swatch-padding: calc(var(--spacing-xs-3) / 2);
  font-size: var(--spacing-xs-4);
}

.swatch-large {
  --swatch-size: var(--spacing-sm-5);
  --swatch-padding: var(--spacing-xs-2);
  font-size: var(--spacing-xs-5);
}

.long-text {
  font-size: .8em;
}

