.product-image {
  width: 100%;
  height: 400px;
  max-height: 100%;
  color: var(--color-black);
  position: relative;
  overflow: hidden;
  background: var(--color-gray-200);

  .slideshow {
    img, video {
      height: 100%;
      object-fit: cover;
    }
  }
  img, video {
    height: 100%;
    object-fit: cover;
  }
}
