@import '../../../variables';

.modal {
  z-index: 1000;
  position: fixed;
  background: white;
  margin: auto;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 400ms ease;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;

  @media screen and (max-width: $screen-s-min) {
    min-width: 100%;
    min-height: 100%;
  }

  display: flex;
  flex-direction: column;

  &.show {
    transform: translate(-50%, -50%) scale(1);
  }

  .close-button {
    position: absolute;
    right: 12px;
    top: 16px;
  }
}

.content-container {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px 20px 40px;
  background-size: cover;
  background-position: center center;
  background-image: url('../../../assets/whoop-pro-landing-background.png');

  @media screen and (max-width: $screen-m-min) {
    padding: 20px;
  }

  .pro-logo {
    height: 60px;
    min-width: 60px;
  }

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: .1em;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  .sub-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: .5px;
    max-width: 310px;
    margin: auto;
    text-align: center;
  }

  .subtitle {
    font-size: 14px;
  }

  ul {
    max-width: 400px;

    @media screen and (max-width: $screen-m-min) {
      flex: 0.5;
    }

    li {
      list-style-image: none;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin: 20px 0;

      .icon {
        color: var(--whoop-color-pro-gold);
        width: 24px;
        height: 24px;
        margin-right: 20px;
        min-width: 30px;

        @media screen and (max-width: $screen-m-min) {
          width: 24px;
          height: 24px;
          min-width: 24px;
        }
      }
    }
  }

  .cta-body {
    margin-bottom: 10px;
    text-align: center;
  }

  .footer {
    font-size: 12px;
    font-family: var(--whoop-font-family-semibold);
    text-align: center;
    max-width: 90%;
    color: var(--whoop-color-dark-gray);
  }

  h3 {
    width: 350px;
    max-width: 90%;
    text-transform: uppercase;
    font-family: var(--whoop-font-family-bold);
    font-size: 15px;
    letter-spacing: 1.5px;
    line-height: 18px;
  }

  a {
    font-family: var(--whoop-font-family-semibold) !important;
    font-size: 12px;
    margin-top: 1em;
  }

  button {
    max-width: 90%;
    margin-top: 15px;
  }
}

.accordion {
  min-height: unset !important;
  padding: 0 !important;

  span {
    margin: 0 !important;
  }

  &>div {
    margin: 0 !important;
  }
}

.faq {
  width: 100%;

  .alert {
    margin: 8px 12px;

    svg {
      box-sizing: content-box;
    }
  }

  .faq-link {
    padding: 20px 24px;

    a {
      color: var(--color-red);
      text-decoration: none;
    }
  }
}

.signin-link {
  cursor: pointer;
}

.whoop-focus-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--header-z-index);
  background-color: transparent;
  pointer-events: none;
  transition: background-color var(--anim-speed-normal);

  &[data-showing='true'] {
    pointer-events: all;
    cursor: pointer;
    background-color: var(--whoop-color-black-alpha-300);
  }
}

.join-whoop-pro-btn {
  background: var(--whoop-color-pro-gold);
  color: var(--whoop-color-black);
  font-weight: 'bold';
  width: fit-content;
}