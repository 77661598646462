.radio-input {
  .option {
    display: flex;
    margin-bottom: 16px;
    cursor: pointer;
    align-items: center;
    font-family: var(--whoop-font-family-normal);
  }

  .labelContainer {
    margin-left: 10px;
    cursor: pointer;
  }

  .shippingMethodRadioInputLabelContainer {
    width: 100%;
    margin-left: 24px;
    cursor: pointer;

    .label {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-weight: 600;
      font-size: 18px;
    }

    .sublabel {
      color: var(--color-black-alpha-500);
    }
  }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 4px solid var(--color-white);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    background: var(--color-white);
    margin: 0;
    cursor: pointer;
  }

  input[type="radio"]:checked {
      background: var(--color-black);
      border: 7px solid var(--color-white);
  }
}
