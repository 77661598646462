.refer-a-friend {
  max-width: 450px;
  text-align: center;

  .step-tracker {
    text-transform: uppercase;
    font-family: var(--whoop-font-family-normal);
    font-size: var(--font-size-s);
    color: var(--color-black);
    letter-spacing: 1.27px;
    text-align: center;
    width: 350px;
    height: 90px;
    font-weight: 600;

    .referral-icon {
      color: var(--color-branding-red);
    }
  }

  .title {
    text-transform: uppercase;
    font-family: var(--whoop-font-family-light);
    font-size: var(--font-size-l);
    color: var(--color-black);
    text-align: center;
    line-height: var(--spacing-sm-5);
  }

  @media (max-width: 576px) {
    .title {
      width: 96%;
      margin: 0 auto;
    }
  }

  .message {
    opacity: 0.5;
    font-family: var(--whoop-font-family-normal);
    font-weight: 600px;
    font-size: var(--font-size-m);
    color: var(--color-black);
    text-align: center;
    line-height: var(--spacing-xs-5);
    margin: var(--spacing-xs-5) 0;
  }

  .link {
    margin-bottom: var(--spacing-xs-5);

    textarea {
      font-family: var(--whoop-font-family-normal);
    }
  }

  .btn-share {
    width: 160px;
    letter-spacing: 2px;
    font-weight: 700;
  }

  .coach {
    color: var(--color-branding-red);
    padding-bottom: 20px;
    font-weight: normal;
  }

  .email-input {
    border-radius: 0;
    width: 300px;
    margin-bottom: 24px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip-text,
  .tooltip-manual {
    visibility: visible;
    opacity: 1;
    width: 120px;
    background-color: var(--color-black-alpha-700);
    color: var(--color-white);
    text-align: center;
    border-radius: 6px;
    padding: var(--spacing-xs) 0;
    position: absolute;
    z-index: 1;
    bottom: 67%;
    left: 50%;
    margin-left: -63px;
    transition: opacity 0.3s;
  }

  .tooltip-text::after,
  .tooltip-manual::after {
    content: '';
    position: absolute;
    top: -32%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--color-black-alpha-700)
      transparent;
  }
}
