.multi-product {
  margin: var(--spacing-sm-3) 0;

  h2 {
    margin-bottom: var(--spacing-xs-2);
    display: flex;
    gap: var(--spacing-xs-2);
    align-items: center;
    flex-wrap: wrap;
  }
}

h4.selected-option-inner {
  margin-top: var(--spacing-xs-2) !important;
  margin-bottom: var(--spacing-xs-2) !important;
  text-transform: none;
  letter-spacing: 0.5px;
  font-family: var(--whoop-font-family-semibold);
}

.few-products-left {
  color: #597483;
  font-weight: 300;
}

.selected-option-outer {
  font-family: var(--whoop-font-family-normal);
  text-transform: none;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: left;
  display: inline;

  &li {
    margin-bottom: var(--spacing-xs-2);
  }
}

.multi-product-badge {
  padding: 4px;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  width: fit-content;
  border-radius: 2px;
  position: relative;
  margin: 0;
  display: inline;
  background: var(--whoop-color-black);
  color: var(--whoop-color-white);
  vertical-align: bottom;

  &.white {
    background: var(--whoop-color-white);
    color: var(--whoop-color-black);
  }

  &.pro {
    background: var(--whoop-color-pro-gold);
    color: var(--whoop-color-black);
  }

  &.gray {
    background: var(--whoop-color-gray-700);
    color: var(--whoop-color-white);
  }
}

.size-swatch-container {
  display: flex;

  .size-swatch {
    width: unset;
  }
  .size-guide {
    text-transform: uppercase;
    margin-left: var(--spacing-xs-3);
  }
}
