.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px;

  .image {
    width: 268px;
    height: auto;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    width: 566px;
    margin: 0 60px 0 50px;

    .title {
      font-size: 50px;
      font-weight: 600;
      line-height: 50px;
      letter-spacing: -0.04em;
      width: 350px;
      margin: 0 0 30px 0;
    }

    .subtitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: -0.03em;
      margin: 0;
    }
  }

  .shareButton {
    height: 65px;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.6px;
  }
}
