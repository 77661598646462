$line-widths: (
  'sm': 1px,
  'md': 2px,
  'lg': 4px,
);

:export {
  @each $name, $size in $line-widths {
    #{$name}: $size;
  }
}

:global(html) {
  @each $name, $size in $line-widths {
    #{'--line-width-' + unquote($name)}: $size;
  }
}

:global {
  .whoop-ui,
  .whoop-spacing {
    @each $name, $size in $line-widths {
      .b-#{unquote($name)} {
        border-style: solid;
        border-width: $size;
      }
      .bx-#{unquote($name)} {
        border-left-width: $size;
        border-left-style: solid;
        border-right-width: $size;
        border-right-style: solid;
      }
      .by-#{unquote($name)} {
        border-top-width: $size;
        border-top-style: solid;
        border-bottom-width: $size;
        border-bottom-style: solid;
      }
      .bt-#{unquote($name)} {
        border-top-width: $size;
        border-top-style: solid;
      }
      .bb-#{unquote($name)} {
        border-bottom-width: $size;
        border-bottom-style: solid;
      }
      .bl-#{unquote($name)} {
        border-left-width: $size;
        border-left-style: solid;
      }
      .br-#{unquote($name)} {
        border-right-width: $size;
        border-right-style: solid;
      }
    }
  }
}
