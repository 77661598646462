@import '../../assets/mxp/css/variables';

.card-component {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    background: var(--whoop-color-white);
    border: 1px solid var(--whoop-color-black-alpha-100);
    box-sizing: border-box;
    border-radius: 6px; 
    overflow: hidden;
}

.card-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 48px;
    background-color: var(--whoop-color-white);
    font-family: var(--whoop-font-family-normal);
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--whoop-color-black-alpha-700);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);;
    padding: .75rem 1.25rem;
    height: 45px;
}