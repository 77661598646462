.shippingOptions {
  margin-bottom: 40px;

  input[type="radio"] {
    width: 35px;
    height: 32px !important;
  }
}

.nextButton {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.03em;
  padding: 0 50px;
}


