.icon-button {
  /* Set index used */
  --color-primary: var(--color-branding-red);
  --color-primary-contrast: var(--color-white);
  --color-border: var(--color-contrast-light);
  --color-contrast: var(--color-black);
  --color-contrast-subtle: var(--color-gray-600);
  --color-contrast-light: var(--color-gray-300);

  --color-ripple-alpha: var(--color-white-alpha-500);
  --color-hover-alpha: var(--color-white-alpha-300);

  --color-disabled: var(--color-gray-600);
  --color-disabled-light: var(--color-gray-300);
}

:global(.theme-enterprise) .icon-button,
:global(.theme-enterprise).icon-button {
  --color-primary: var(--color-strain-blue);
  --color-primary-contrast: var(--color-white);
  --color-border: var(--color-contrast-light);
  --color-contrast: var(--color-strain-blue);

  --color-ripple-alpha: var(--color-white-alpha-500);
  --color-hover-alpha: var(--color-white-alpha-300);

  --color-disabled: var(--color-gray-600);
  --color-disabled-light: var(--color-gray-300);
}

:global(.theme-dark) .icon-button,
:global(.theme-dark).icon-button {
  --color-primary: var(--color-white);
  --color-primary-contrast: var(--color-black);
  --color-border: var(--color-white);
  --color-contrast: var(--color-white);

  --color-ripple-alpha: var(--color-white-alpha-300);
  --color-hover-alpha: var(--color-white-alpha-500);

  --color-disabled: var(--color-gray-600);
  --color-disabled-light: var(--color-gray-300);
}

.icon-button {
  --color-ripple-alpha: var(--color-white-alpha-500);
  --color-hover-alpha: var(--color-white-alpha-300);
  --background-color: transparent;
}

.icon-button {
  display: flex;
  justify-content: center;
  position: relative;
  -webkit-backface-visibility: hidden;
  border: none;
  width: var(--round-size);
  height: var(--round-size);
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
  background: transparent;
  cursor: pointer;
  .icon {
    opacity: 1;
    position: relative;
    -webkit-backface-visibility: hidden;
    justify-content: center;
    align-self: center;
    flex-shrink: 0;
  }

  &:focus {
    outline: none;
  }
  /* before acts as the hover */
  &:not([disabled]):before {
    content: '';
    pointer-events: none;
    position: absolute;
    border-radius: 50%;
    padding: 25%;
    background: transparent;
    transform: scale(3.5);
    transition: background-color var(--anim-speed-normal) var(--anim-interp-default);
    transform-origin: center;
    left: 25%;
    top: 25%;
  }
  //&:not([disabled]):focus:before, // TODO: need to standardize focus
  &:not([disabled]):hover:before {
    background: var(--color-hover-alpha);
  }
  &[disabled] {
    cursor: default;
  }
}

/* click ripples */
@keyframes ripple-anim {
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
.ripple {
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  padding: 1%;
  transform: scale(0);
  animation: ripple-anim var(--anim-speed-normal) ease-out;
  background: var(--color-ripple-alpha);
}


.icon-button-primary {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary);

  &[disabled] {
    color: var(--color-disabled-light);
    background-color: var(--color-disabled);
  }
}
.icon-button-secondary {
  color: var(--color-primary);
  border: solid var(--line-width-md) var(--color-border);
  --color-ripple-alpha: var(--color-black-alpha-100);
  --color-hover-alpha: var(--color-black-alpha-50);

  &[disabled] {
    color: var(--color-disabled);
  }
}
.icon-button-normal {
  color: var(--color-contrast);
  border: solid var(--line-width-md) var(--color-contrast);
  --color-ripple-alpha: var(--color-black-alpha-100);
  --color-hover-alpha: var(--color-black-alpha-50);

  &[disabled] {
    color: var(--color-disabled);
    border-color: var(--color-disabled);
  }
}
.icon-button-link {
  color: var(--color-contrast-subtle);
  --color-ripple-alpha: var(--color-black-alpha-100);
  --color-hover-alpha: var(--color-black-alpha-50);

  &[disabled] {
    color: var(--color-disabled);
  }
}

.icon-button-small {
  --round-size: var(--spacing-sm-3);
}
.icon-button-medium {
  --round-size: var(--spacing-sm-5);
}
.icon-button-large {
  --round-size: var(--spacing-md-2);
}

.icon-small {
  width: var(--spacing-xs-4);
  height: var(--spacing-xs-4);
}
.icon-medium {
  width: var(--spacing-xs-5);
  height: var(--spacing-xs-5);
}
.icon-large {
  width: var(--spacing-sm-2);
  height: var(--spacing-sm-2);
}
