:global(.theme-square) .toggle-button,
:global(.theme-square).toggle-button {
  border-radius: 8px;

  input[type='radio']:checked + label {
    border-radius: 8px;
  }
}

:global(.theme-dark) .toggle-button,
:global(.theme-dark).toggle-button {
  border: none;
  border-radius: 8px;
  background-color: var(--color-white-alpha-100);
  height: 100%;
  padding: 0px;

  label {
    color: var(--color-white-alpha-900);
    font-weight: 700;
    font-size: 14.22px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;

    &:hover {
      background-color: var(--color-white-alpha-200);
    }
  }

  input[type='radio']:checked + label {
    border: none;
    border-radius: 8px;
    background-color: var(--color-white-alpha-900);
    color: var(--color-black-alpha-900);
    margin: 0px;

    &:hover {
      background-color: var(--color-white-alpha-700);
    }
  }
}

.toggle-button {
  text-transform: uppercase;
  border: 2px solid var(--color-gray-600);
  border-radius: 100px; // just needs to be > half the height of the component
  display: flex;
  justify-content: space-evenly;
  white-space: nowrap;

  input[type='radio'] {
    display: none;
    width: 100%;
  }

  label {
    color: var(--color-gray-600);
    display: inline-block;
    font-family: var(--whoop-font-family-normal);
    font-weight: 700;
    font-size: var(--font-size-s);
    letter-spacing: 0.1em;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  input[type='radio']:checked + label {
    color: var(--color-black);
    border: var(--line-width-md) solid var(--color-black);
    border-radius: 100px; // just needs to be > half the height of the component
    margin: -2px -2px;
  }
}

.small {
  label {
    font-size: var(--font-size-s);
    line-height: var(--spacing-xs-3);
    padding: var(--spacing-xs-2) var(--spacing-sm);
  }
}

.medium {
  label {
    font-size: var(--font-size-s);
    line-height: var(--spacing-xs-3);
    padding: var(--spacing-xs-3) var(--spacing-sm-3);
  }
}
.large {
  label {
    font-size: var(--whoop-font-size-m);
    line-height: var(--spacing-xs-5);
    padding: var(--spacing-xs-4) var(--spacing-sm-3);
  }
}
