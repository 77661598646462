.checkoutSection {
  all: unset;
  width: 100%;
  border-top: 1px solid var(--whoop-color-gray-500);
  cursor: pointer;
  transition: max-height .35s;
  max-width: 900px;

  &.bottomBorder {
    border-bottom: 1px solid var(--whoop-color-gray-500);
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;

    .title {
      color: var(--whoop-color-black);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.54px;
      text-transform: uppercase;
    }

    .status {
      visibility: hidden;
      transition: visibility .35s ease-in-out;
    }

    .completed {
      animation: flyInRight .2s ease-in-out;
      visibility: visible;
    }
  }

  .check {
    background: black;
    color: white;
    padding: 5px;
    border-radius: 100%;
    margin-left: 28px;
  }
}

.collapsible {
  overflow: hidden;
  max-height: 0;
  padding: 0 1px;
  height: 0;
  transition: max-height var(--anim-speed-normal) var(--anim-interp-default);
}

.open {
  height: auto;
  max-height: 1000px;
  padding: 15px 1px 15px;
}

@keyframes flyInRight {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}