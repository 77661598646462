/* define overrideable variables */
.input {
  /* Set index used */
  --color-contrast: var(--color-black);
  --color-contrast-light: var(--color-black-alpha-300);
  --color-contrast-mid: var(--color-black-alpha-500);
  --color-disabled: var(--color-black-alpha-50);
  --color-focus: var(--color-black);
  --color-error: var(--color-red);

  --font-size: var(--font-size-m);
  --font-size-small: var(--font-size-s);
  --border-width: var(--line-width-sm);
  --padding-horizontal: var(--spacing-xs-4);
  --padding-vertical: var(--spacing-xs-3);
  --padding-small: var(--spacing-xs); // padding of small text
}

:global(.theme-dark) .input,
:global(.theme-dark).input {
  /* Set index used */
  --color-contrast: var(--color-white);
  --color-contrast-light: var(--color-white-alpha-300);
  --color-contrast-mid: var(--color-white-alpha-500);
  --color-disabled: var(--color-white-alpha-50);
  --color-focus: var(--color-white);
  --color-error: var(--color-branding-red);
}

.input {
  color: var(--color-contrast);
  position: relative;
  display: inline-block;
  padding: 0;
  text-align: left;

  legend,
  label {
    font-size: var(--font-size);
    font-family: var(--whoop-font-family-light);
    text-transform: none;
    font-weight: 400;
    pointer-events: none;
    color: var(--color-contrast-light);
    line-height: 1em;
    letter-spacing: var(--font-letter-spacing-normal);
  }

  &.just-placeholder fieldset {
    top: 0;
    height: 100%;
  }

  fieldset {
    position: absolute;
    top: calc(var(--font-size-small) / -2);
    left: calc(var(--border-width) * -1);
    height: calc(100% + var(--font-size-small) / 2);
    width: 100%;
    box-sizing: border-box;
    border: solid var(--border-width) var(--color-contrast-light);
    border-radius: 3px;
    pointer-events: none;
    padding: 0 var(--padding-horizontal);
    text-align: left;
    margin: 0;

    legend {
      font-size: calc(var(--font-size) * .75);
      visibility: hidden;
      white-space: nowrap;
      padding: 0;
      max-width: 0;
      transition: padding var(--anim-speed-fast) var(--anim-interp-default),
        max-width var(--anim-speed-fast) var(--anim-interp-default);
    }
  }

  label {
    position: absolute;
    top: calc(50% - 0.5em); // place in center - half the text height (.5em)
    left: var(--padding-horizontal);
    transform-origin: left center;
    transition: transform var(--anim-speed-fast) var(--anim-interp-default);
    max-width: calc(100% - var(--padding-horizontal) * 2);

    span {
      color: var(--color-error);
    }
  }
  // Active state
  &.active legend {
    padding: 0 var(--padding-small);
    max-width: calc(100% - var(--padding-horizontal) * 2);
  }
  &.active label {
    transform: scale(0.75) translate(calc(var(--padding-small) + 1px), -1.75em);
  }

  // Focus state
  &.focused fieldset {
    border-color: var(--color-focus);
  }
  &.focused:not(.error) label {
    color: var(--color-focus);
  }

  // error state
  &.error:not(.focused) fieldset {
    border-color: var(--color-error);
  }
  &.error label {
    color: var(--color-error);
  }
  &.error .sub-text {
    color: var(--color-error);
  }

  /* Remove styling from number button */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input {
    -moz-appearance: textfield;
  }

  input {
    font-size: var(--font-size);
    letter-spacing: var(--font-letter-spacing-type);
    padding: var(--padding-vertical) var(--padding-horizontal);
    border: none;
    background: transparent;
    font-family: var(--whoop-font-family-normal);
    color: var(--color-contrast);
    box-sizing: border-box;
    line-height: 1em;
    width: 100%;
    height: 40px;

    &:focus {
      outline: none;
    }
    &:disabled {
      color: var(--color-contrast-light);
      background-color: var(--color-disabled);
    }

    &::placeholder {
      color: var(--color-contrast-light);
      line-height: 1em;
    }
  }

  .sub-text {
    font-family: var(--whoop-font-family-light);
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    padding: var(--padding-small) var(--padding-horizontal);
    font-size: var(--font-size-small);
    color: var(--color-contrast-mid);
  }


  .clickable {
    cursor: pointer;
  }
}
