.layered-image {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
