@import '../../../variables';

:global(.theme-dark) .accordion,
:global(.theme-dark).accordion {
  .primary {
    background: var(--color-black);
    color: var(--color-white);
  }
}

.accordion {
  .product-tabs {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .secondary {
    background: var(--color-white-alpha-100);
    color: var(--color-white-alpha-500);
  }

  .primary {
    background: var(--color-white);
    color: var(--color-black);
  }

  .collapsible {
    overflow: hidden;
    padding-left: 16px;
    padding-right: 18px;
    border-radius: 8px;
    cursor: pointer;

    &[aria-expanded='true'] {
      .arrow-icon {
        transform: rotate(180deg);
      }

      .collapsible--inner {
        margin-top: -8px;
      }
    }

    &--button {
      margin: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 18px;
      font: inherit;
      overflow: visible;
      font-weight: 500;
      font-size: 16px;
      font-family: var(--whoop-font-family-bold);
      width: 100%;
      text-align: left;
    }

    &--inner {
      margin-top: 0;
      transition: max-height .3s ease-in-out, margin-top .3s;
    }

    &-content {
      word-break: break-word;
      font-family: var(--whoop-font-family-light);
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      padding-bottom: 24px;
    }
  }

  .arrow-icon {
    height: 12px;
    width: 16px;
    min-width: 16px;
    min-height: 12px;
    transition: transform .3s ease-in-out;
  }
}
