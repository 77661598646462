.focus-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background-color: transparent;
  pointer-events: none;
  transition: background var(--anim-speed-normal);

  &.show {
    pointer-events: all;
    background-color: var(--color-black-alpha-300);
  }
}
