:global(.theme-join-flow) .gift-message-section,
:global(.theme-join-flow).gift-message-section {
  min-width: 600px;

  .add-gift-message-alert {
    box-shadow: none;

    .alert-content {
      align-items: center;

      .alert-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        text-transform: initial;
      }

      .alert-body {
        font-size: 18px;
        font-weight: 400;
        color: var(--whoop-color-black-alpha-500);
      }

      .alert-arrow {
        svg {
          height: 15px;
          width: 15px;
        }
      }
    }
  }
}

.gift-message-section {
  min-width: 324px;
  max-width: 600px;
  margin: 32px auto 0;
  font-size: 14px;
  color: var(--color-black);
  line-height: 21px;

  .add-gift-message {
    display: flex;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
  }

  .add-gift-message-alert {
    line-height: 14px;
    cursor: pointer;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.04);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    .alert-content {
      display: flex;
      justify-content: space-between;
    }

    .alert-title {}

    .alert-body {
      font-weight: 300;
      text-transform: none;
    }

    .alert-arrow {
      opacity: 0.25;
      margin-left: 16px;
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .gift-message-link {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    letter-spacing: 0.1875px;
  }

  .gift-message-container {
    background-color: var(--color-white);
    box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 16px;
  }

  .gift-message-header {
    border-bottom: 0.5px solid rgba(166, 166, 166, .5);
    padding-bottom: 12px;
    font-family: var(--whoop-font-family-light);
    letter-spacing: 0.5px;

    .gift-message-bold {
      font-weight: 600;
      font-family: var(--whoop-font-family-normal);
    }

    a {
      text-decoration: none;
      color: var(--color-strain-blue);
      font-family: var(--whoop-font-family-normal);
      font-weight: 600;
    }
  }

  .giftee-header {
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-family: var(--whoop-font-family-normal);
  }

  .gift-message-content {
    padding-top: 16px;
    font-size: 14px;

    .top-line {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 600;
        text-transform: uppercase;
      }

      .edit {
        font-weight: 600;
        font-size: 14px;
        text-align: right;
        letter-spacing: 0.5px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .gift-message-contents {
    font-weight: 300;
    word-break: break-word;

    .recipient-email {
      font-size: 14px;
    }

    .spaced-line {
      padding-bottom: 8px;
    }
  }

  @media only screen and (min-width: 984px) {
    .add-gift-message {
      justify-content: left;
    }
  }
}
