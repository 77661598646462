.cart-item-wrapper {
  .cart-item {
    display: flex;
    justify-content: space-between;
    padding: 24px 0px;
    gap: 24px;
  }
  .cart-image {
    display: inline-block;
    height: 48px;
    width: 48px;
    margin-right: 20px;
  }
  .cart-item-description-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    .cart-description-row {
      display: flex;
      justify-content: space-between;
      .cart-item-label {
        font-size: 18px;
        vertical-align: 1rem;
        flex: 1;
      }
      .cart-item-price {
        font-size: var(--spacing-xs-5);
        text-align: right;
        float: right;
        letter-spacing: 0;
      }
      .sub-label {
        font-size: var(--spacing-xs-4);
        color: var(--color-inactive-gray);
        max-width: 80%;
      }
      .quantity-selector {
        display: flex;
        align-items: flex-start;
        .dropdown {
          margin-top: 2px;
        }
      }
    }
  }
}

.bottomBorder {
  border-bottom: 1px solid #d8d8d8;
}
.horizontal {
  border-radius: var(--spacing-xs-2);
  background: #d7d7d7;
}
.upsell {
  margin-top: var(--spacing-xs-5);
}
.alert {
  margin-bottom: 24px;
}
