@import 'src/components/layout/responsive.mixin';

.modal {
  border-radius: 12px !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-height: 682px;
  max-width: calc(100% - 20px);
  padding: 10px;

  @include less-than-lg {
    max-height: 405px;
  }

  .content {
    display: flex;
    flex-direction: row;
    @include less-than-md {
      flex-direction: column;
      max-height: none;
      max-width: none;
      width: 100%;
      padding: 0;
    }
  }

  .imageContainer {
    margin-right: 20px;

    @include less-than-md {
      width: 100%;
      margin-right: 0;
    }

    .image {
      object-fit: cover;
      width: 396px;
      height: 505px;
      border-radius: 10px;

      @include less-than-lg {
        width: 318px;
        height: 405px;
      }

      @include less-than-md {
        min-width: 100%;
        max-height: 450px;
        height: auto;
        border-radius: 0;
        margin: 0;
      }
    }
  }

  .accordion {
    margin: unset;
    & > div {
      margin: 25px 0;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;
    justify-content: space-between;
    padding-right: 40px;

    @include less-than-md {
      width: calc(100% - 20px);
      padding: 10px;
    }

    .textContainer {
      padding-left: 10px;

      .title {
        text-transform: uppercase;
        line-height: 35px;
        margin: 16px 0;
      }

      .subtitle {
        text-transform: uppercase;
        margin: 16px 0;
      }

      .body {
        font-size: 12px;

        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .buttonsContainer {
      max-width: 250px;
      width: 100%;
      margin: 20px 0;

      @include less-than-md {
        max-width: none;
        margin-bottom: 0;
        padding-right: 0;
      }

      .actionButton {
        width: 100%;
        height: auto;
        white-space: pre-wrap;
        word-break: keep-all;
        margin: 4px 0;
      }
    }
  }
}
