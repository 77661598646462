@import '../../assets/mxp/css/_variables';


.chipRoot {
    border: none;
    cursor: default;
    height: 24px;
    display: inline-flex;
    outline: 0;
    padding: 8px;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: var(--whoop-font-family-normal);
    white-space: nowrap;
    border-radius: 4px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    color: var(--color-white);
    display: flex;
    background: var(--color-black);
    margin: 4px 4px;

    .chipLabel {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .closeSvg {
        width: 10px;
        height: 10px;
        padding-left: 8px;
    }
}