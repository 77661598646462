.react-date-picker {
  font-family: var(--whoop-font-family-normal);
  --color-contrast: var(--color-black);
  --color-contrast-light: var(--color-black-alpha-300);

  & > * {
    min-width: 324px;
  }

  :global {
    @import '~react-calendar/dist/Calendar';
    @import '~react-date-picker/dist/DatePicker';

    .react-date-picker__wrapper {
      border: solid var(--line-width-sm) var(--color-contrast-light);
      border-radius: 3px;
      height: 40px;
      box-sizing: border-box;

      &:focus-within {
        border-color: var(--color-contrast);
      }
    }

    .react-date-picker__calendar-button {
      order: 1;
      height: unset;
      width: 44px;
      padding-left: 14px;
    }

    .react-date-picker__button svg {
      display: inherit;
      width: 20px;
      height: 20px;
      color: #C4C4C4;
    }

    .react-date-picker__inputGroup {
      order: 2;
      cursor: text;
    }

    .react-date-picker__inputGroup__input:invalid {
      background: transparent;
    }

    input.react-date-picker__inputGroup__input:focus-visible {
      outline: none;
    }

    .react-date-picker__calendar.react-date-picker__calendar--open {
      width: 100% !important;
      max-width: 350px;
      padding-bottom: 32px;
    }

    .react-date-picker__calendar {
      inset: 100% auto auto auto !important;
      top: 40px !important;
    }

    .react-calendar {
      box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.04);
      border: none;
    }

    .react-calendar__navigation {
      margin-bottom: 0;
    }

    .react-calendar__navigation button[disabled] {
      background-color: transparent;
      cursor: not-allowed;
    }

    .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
      background-color: transparent;
    }

    button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
    button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      display: none;
    }

    button.react-calendar__navigation__label {
      cursor: default;
      min-width: 160px;
    }

    .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
      text-transform: uppercase;
      font-family: var(--whoop-font-family-normal);
      font-style: normal;
      font-weight: bold;
      font-size: 13.8254px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: var(--color-black);
    }

    .react-calendar__month-view__weekdays__weekday abbr[title] {
      font-family: var(--whoop-font-family-normal);
      font-style: normal;
      font-weight: bold;
      font-size: 8px;
      line-height: 10px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: var(--color-black);
      mix-blend-mode: normal;
      opacity: 0.5;
      text-decoration: none;
    }

    button.react-calendar__tile {
      font-family: var(--dinpro-font-family-bold);
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 2px;
      width: 50px;
      height: 50px;

      &:disabled {
        background: transparent;
        color: var(--color-gray-500);
        cursor: not-allowed;

        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        background: #e6e6e6;
      }

      &.react-calendar__tile--active {
        background: transparent;
        color: var(--color-branding-red);

        &:hover {
          background: #e6e6e6;
        }
      }
    }

    .react-calendar__tile--now,
    .react-calendar__tile--now:enabled:focus {
      background: transparent;
    }

    .react-calendar__tile--now:enabled:hover {
      background: #e6e6e6;
    }

    button.react-calendar__month-view__days__day,
    button.react-calendar__month-view__days__day--weekend {
      color: var(--color-black);
    }

    button.react-calendar__month-view__days__day--neighboringMonth {
      color: #757575;
    }
  }
}
