$screen-m: 1008px;
$screen-s: 641px;
$screen-xs: 400px;

.sizing-guide {
  padding: 24px 32px;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: var(--whoop-font-family-normal);

  // This fixes broken text fields
  div[class*='input-module__active__'] label {
    transform: scale(.8) translate(8px, -2.2em);
  }

  @media screen and (max-width: $screen-s) {
    padding: 24px 0;
  }

  h1, h2, h3 {
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
  }
  h1 {
    font-size: 28px;
    font-weight: 500;
  }
  h2 {
    font-size: 16px;
    color: var(--color-gray-700);
  }
  h3 {
    font-size: 14px;
  }
  p {
    margin: 14px 30px;
    letter-spacing: .5px;
  }

  .toggle {
    margin: 20px auto 4px;
    max-width: 400px;
    padding: 0 10px;
  }

  .tables-container {
    display: flex;
    transition: transform 400ms ease;

    &.slide-left {
      transform: translateX(-100%);
    }
    &.slide-right {
      transform: translateX(100%);
    }

    div {
      flex: 1;
      min-width: 100%;
      padding: 14px 10px;
      box-sizing: border-box;

      table {
        tr:last-child {
          border-bottom: none;
        }
        tbody {
          tr th {
            text-align: right;
            text-transform: uppercase;
          }
        }

        th, td {
          max-width: 80px;
        }
      }
    }
  }

  table {
    margin: auto;
    border-collapse: collapse;

    tr {
      border-bottom: solid black 1px;
    }

    th, td {
      box-sizing: content-box;
      padding: 2px 6px;
    }
  }

  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .instructions {
    padding: 0 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    --block-size: 250px;

    overflow: hidden;
    max-height: 0;
    transition: max-height 400ms ease, padding 400ms ease;

    &.expanded {
      max-height: 450px;
      padding: 10px 32px;

      @media screen and (max-width: $screen-s) {
        max-height: 700px;
      }
    }

    table {
      flex: 1;
      min-width: var(--block-size);
      text-align: left;
      max-width: 500px;
      margin: 40px 0;

      th {
        white-space: nowrap;
        vertical-align: baseline;
      }
      tr:last-child {
        border-bottom: none;
      }

    }

    img {
      flex: 1;
      min-width: var(--block-size);
      max-height: var(--block-size);
      object-fit: contain;
    }
  }

  .preview {
    margin: auto;
  }

}
