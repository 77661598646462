.img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  &:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.img-container{
  height: 100%;
  width: 100%;
}

.product-description {
  font-weight: 200;
}

.top-padding {
  margin-top: 30px;
}
