.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 606px;
  padding: 30px;

  .title {
    font-size: 35px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: -0.04em;
    margin: 0;
    text-transform: uppercase;
  }
}
