.radio-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .button {
    background-color: transparent;
    color: black;
    border-radius: 2px;
    border: 2px solid black;
    flex: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 26px;
    min-height: 8px;
    &.selected {
      background-color: black;
      color: white;
      border-color: transparent;
      pointer-events: none;
    }
    &[disabled] {
      color: var(--color-disabled);
      border-color: var(--color-disabled);
    }
  }

  .buttonLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .button-small {
    min-width: 112px;
    padding: var(--spacing-xs-2) var(--spacing-xs-4);
    font-size: var(--font-size-s);
    letter-spacing: 0.1em;
    &:not(:first-child) {
      margin-left: var(--spacing-xs-2);
    }
  }
  
  .button-medium {
    min-width: 112px;
    padding: var(--spacing-xs-3) var(--spacing-sm);
    font-size: var(--font-size-s);
    letter-spacing: 0.1em;
    white-space: nowrap;
    &:not(:first-child) {
      margin-left: var(--spacing-xs-3);
    }
  }
  
  .button-large {
    padding: var(--spacing-xs-4) var(--spacing-sm-3);
    font-size: var(--whoop-font-size-m);
    line-height: var(--spacing-xs-5);
    min-width: 140px;
  
    &:not(:first-child) {
      margin-left: var(--spacing-xs-4);
    }
  }  
}