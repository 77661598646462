.input {
  display: block;
  width: 100%;
  margin: 2rem auto;
  .inputs:invalid {
    border-color: var(--whoop-color-red);
  }

  Input {
    color: #4d4d4d !important;
    font-weight: 400;
    letter-spacing: -0.48px !important;
    height: 68px !important;
  }
}

.dropdown {
  display: block;
  width: 100%;
  margin: 2rem auto;
  height: 68px;
}

.column {
  .input {
    margin: 0;
  }

  .dropdown {
    margin: 0;
  }
}

.row {
  display: block;
  margin: 1rem auto;
}
.row:first-of-type {
  margin-top: 0
}
.row:last-of-type {
  margin-bottom: 0
}

.nextButton {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.03em;
  padding: 0 50px;
}
