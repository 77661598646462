@import '../layout/responsive.mixin';

.uniteFooterWrapper {
  @include less-than-md {
    justify-content: center;
  }
  background: var(--whoop-color-black);
  border: 1px solid var(--whoop-color-black);
  box-sizing: border-box;

  min-height: 95px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  position: absolute;
  bottom: 0;

  .logo {
    margin-left: 30px;
  }

  .footerLinks {
    @include less-than-md {
      margin-right: 0px;
    }
    color: var(--whoop-color-white);
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-right: 20px;

    p {
      margin-right: 12px;
    }

    .copyright {
      font-family: ProximaNova-Semibold;
      font-style: normal;
      font-size: 12px;
      line-height: 16px;
      margin: 0px;
    }

    .details {
      font-weight: 400;
      text-transform: none;
      letter-spacing: 0;
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  div.footerLinks .boldLink {
    font-weight: 700;
    cursor: default;
  }

  div.footerLinks > span {
    margin: 15px 20px 15px 0px;
  }

  div.footerLinks > span:last-child {
    margin-right: 30px;
  }

  .actionContainer {
    margin-left: 20px;

    button {
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-align: center;
    }
  }
}

.relativeContainer {
  position: relative;
}



