:global(.theme-unite) .wrapper,
:global(.theme-unite).wrapper {
    .tooltip {
      box-shadow: 0 0 16px 0 var(--color-black-alpha-300);
    }
}

.wrapper {
  display: inline-block;
  position: relative;

  .inline {
    display: inline;
  }

  .tooltip {
    position: absolute;
    border-radius: var(--spacing-xs-2);
    left: 50%;
    transform: translateX(-50%);
    padding: var(--spacing-xs-2);
    color: var(--color-black);
    background: var(--color-white);
    box-shadow: 0 0 24px 0 var(--color-black-alpha-100);
    font-size: 14px;
    line-height: 1;
    z-index: 100;
    white-space: nowrap;

    &::before {
      content: "";
      left: 50%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: var(--spacing-xs);
      margin-left: calc(var(--spacing-xs) * -1);
    }

    &.top::before {
        top: 100%;
        border-top-color: var(--color-white);
    }

    &.right {
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &::before {
        left: calc(var(--spacing-xs) * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: var(--color-white);
      }
    }

    &.bottom::before {
        bottom: 100%;
        border-bottom-color: var(--color-white);
    }

    &.left {
      left: auto;
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &::before {
        left: auto;
        right: calc(var(--spacing-xs) * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: var(--color-white);
      }
    }
  }
}
