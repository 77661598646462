:global(.theme-join-flow) .subtotal,
:global(.theme-join-flow).subtotal {
  font-size: 18px;
  font-weight: 400;

  .line {
    .label {
      color: var(--color-inactive-gray);
    }

    .at-checkout {
      color: var(--color-inactive-gray);
    }

    .price {
      --color-contrast-light: var(--color-gray-500);
      color: var(--color-inactive-gray);
      font-size: 18px;

      .bold {
        font-weight: 600;
      }

      .free-text {
        font-weight: 600;
      }

      .muted {
        color: var(--color-gray-500);
      }
    }

    .total {
      color: var(--color-black)
    }
  }

  hr {
    border: 1px solid var(--color-black-alpha-100);
  }
}

.subtotal {
  font-family: var(--whoop-font-family-normal);
  font-size: 14px;
  width: 100%;
  margin: 0 auto;

  .line {
    display: flex;
    align-items: center;
    min-height: 1.4em;

    .label {
      flex: 1;
      color: var(--color-gray-700);
    }

    .at-checkout {
      font-size: 0.8em;
      color: var(--color-gray-700);
    }

    .price {
      text-align: right;
      margin: 0;

      .bold {
        font-weight: bold;
      }

      .free-text {
        color: var(--color-branding-red);
        font-weight: bold;
      }

      .muted {
        color: var(--color-gray-700);
      }
    }
  }
}
