.price-container {
  --color-sale-contrast: var(--color-white);
  --color-contrast-light: var(--color-gray-700);

  letter-spacing: 0.12em;
  font-family: var(--whoop-font-family-normal);
  font-size: var(--whoop-font-size-m);
  line-height: 1.65;
  display: flex;
  align-items: center;
  white-space: nowrap;

  .original-price {
    color: var(--color-contrast-light);
    text-decoration: line-through;
    font-weight: normal;
  }

  & > *:not(:last-child) {
    margin-right: var(--spacing-xs);
  }

  .pro-logo {
    width: 1.2em;
    height: 1.2em;
  }
}
