/* define overrideable variables */
.number-input {
  /* Set index used */
  --color-contrast: var(--color-black);
  --color-contrast-light: var(--color-black-alpha-300);
  --color-contrast-mid: var(--color-black-alpha-500);
  --color-ripple-alpha: var(--color-black-alpha-100);
  --color-hover-alpha: var(--color-black-alpha-50);

  --border-width: var(--line-width-sm);
  // subtract border to keep component height at 40px (inside grid)
  --inner-component-size: calc(var(--spacing-sm-5) - var(--border-width) * 2)
}

.number-input {
  font-family: var(--dinpro-font-family-normal);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: var(--font-letter-spacing);
  font-size: var(--font-size-m);

  color: var(--color-contrast);
  border: solid var(--border-width) var(--color-contrast-light);
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: fit-content;

  &:focus-within {
    border-color: var(--color-focus);
  }

  /* Remove styling from number button */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number'] {
    padding: 0;
    border: none;
    text-align: center;
    box-sizing: border-box;
    height: var(--inner-component-size);
    width: var(--spacing-sm-2);

    &:focus {
      outline: none;
    }

    &[disabled] {
      background-color: transparent;
      color: var(--color-contrast-mid);
    }
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    font-family: var(--dinpro-font-family-light);
    font-size: var(--spacing-sm);
    height: var(--inner-component-size);
    width: var(--inner-component-size);
    padding: 0 0 6px; // 6px is not within grid-system, this is used to center +/- characters
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    &[disabled] {
      cursor: default;
      color: var(--color-contrast-mid);
    }
  }
}

/* click ripples */
@keyframes ripple-anim {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
.ripple {
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  padding: 1%;
  transform: scale(0);
  animation: ripple-anim var(--anim-speed-slow) ease-out;
  background: var(--color-ripple-alpha);
}
