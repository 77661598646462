.statusTag {
    display: inline-flex;
    text-align: center;
}

.textFormatting {
    font-family: var(--whoop-font-family-normal);
    font-weight: 700;
    letter-spacing: 0.1em;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    margin: 4px;
    color: var(--color-dark);
}

:global(.theme-dark) .textFormatting,
:global(.theme-dark).textFormatting {
    color: var(--color-white);
}

.background-color-blue {
    background-color: var(--color-strain-blue-alpha-200);
}

.background-color-green {
    background-color: var(--color-green-alpha-200);
}

.background-color-yellow {
    background-color: var(--color-yellow-alpha-200);
}

.background-color-red {
    background-color: var(--color-red-alpha-200);
}

.background-color-gray {
    background-color: var(--color-black-alpha-100);
}

.background-color-magenta {
  background-color: var(--color-magenta-alpha-200);
}

:global(.theme-dark) .background-color-blue,
:global(.theme-dark).background-color-blue {
    background-color: var(--color-strain-blue-alpha-300);
}

:global(.theme-dark) .background-color-green
:global(.theme-dark).background-color-green {
    background-color: var(--color-green-alpha-300);
}

:global(.theme-dark) .background-color-yellow
:global(.theme-dark).background-color-yellow {
    background-color: var(--color-yellow-alpha-300);
}

:global(.theme-dark) .background-color-red
:global(.theme-dark).background-color-red {
    background-color: var(--color-red-alpha-300);
}

:global(.theme-dark) .background-color-gray
:global(.theme-dark).background-color-gray {
    background-color: var(--color-white-alpha-300);
}

:global(.theme-dark) .background-color-magenta
:global(.theme-dark).background-color-magenta {
  background-color: var(--color-magenta);
}
